
import { AUTH_USER, AUTH_ERROR ,AUTH_USER_CREDENTIALS} from './types';
//import React from 'react';
//import dispatch from 'react';
//import { TRUE } from 'node-sass';
import jwt_decode from "jwt-decode";


export const signin = (formProps, callback) => async dispatch => {
    try {
        ////console.log(formProps)
        var myHeaders = new Headers();


        var formdata = new FormData();
        formdata.append("grant_type", "password");
        formdata.append("username", formProps.username);
        formdata.append("password", formProps.password);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL_Orchestrator + "/token", requestOptions)
            .then(response => {
                if (response.ok) {
                    return  response.text()
                  } else {
                    throw new Error('Something went wrong');
                  }
            })
            .then(data => {
                var body = JSON.parse(data)
                ////console.log(body.access_token)
                var token_decoded=jwt_decode(body.access_token)
                ////console.log(token_decoded)
                var user_id=token_decoded.userid;
                return {token: body.access_token,user_id}
                
                // iau user_id , fac req pentru bot-id si le salvez in state ( redux)
                // callback();
            })
            .then(data=>{
                var myHeaders_2 = new Headers();
                //console.log(data) 
                myHeaders_2.append("Accept", "application/json");
                myHeaders_2.append("Authorization", "Bearer "+data.token);

                var requestOptions_2 = {
                method: 'GET',
                headers: myHeaders_2
                };
                
                fetch(process.env.REACT_APP_URL_Orchestrator+"/bot/user/"+data.user_id, requestOptions_2)
                .then(response => response.json())
                .then(result =>{
                    //console.log(result)
                    var bot_id=result[0].id;
                    var payload={user_id:data.user_id,bot_id};
                    dispatch({ type: AUTH_USER_CREDENTIALS, payload:payload });
                    dispatch({ type: AUTH_USER, payload: data.token })
                       
                })
                .catch(error => console.log('error', error));
            })
            .catch(() => {
                dispatch({ type: AUTH_ERROR, payload: 'Authentication Error' });
            })

    } catch (e) {
        dispatch({ type: AUTH_ERROR, payload: 'Authentication Error' });
    };
};

export const signout = (callback) => async dispatch => {
    dispatch({ type: AUTH_USER, payload: '' });
    callback();
    return {
        type: AUTH_USER,
        payload: ''
    };
};

