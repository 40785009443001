/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
//import Login from "components/Footers/AuthFooter";
import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { NavLink } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Row,
  Input,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";

import './index.css';

import mainLogo from './exclamationSign.png';

const required = value => value ? undefined : 'Required'
const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
const renderField = ({
  input,
  label,
  type,
  meta: { touched, error, warning }
}) => (
  <div style={{ width: "89%" }}>
    <Input {...input} placeholder={label} type={type} />
    {touched && ((error && <div> <img src={mainLogo} style={{ width: "16px", height: "16px", marginLeft: "45px" }} /> <span style={{ color: "red" }}>{error}</span></div>) || (warning && <span>{warning}</span>))}
  </div>
)


const Logins = props => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [mail, setMail] = React.useState(false);
  const [sendMail, setSendMail] = React.useState(false);
  const [emailValidation, setEmailValidation] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function resetPassword() {
    var validare = validateEmail(mail);
    if (validare !== null) {
      setEmailValidation(false)
      var myHeaders = new Headers();
      myHeaders.append("accept", "application/json");
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "email": mail
      });
      //console.log(mail)
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(process.env.REACT_APP_URL_Orchestrator + "/flows/reset-password", requestOptions)
        .then(response => response.text())
        .then(result => {
          console.log(result)
          setSendMail(true)
        })
        .catch(error => console.log('error', error));
    }
    else {
      setEmailValidation(true)
    }
  }
  function changeMail(e) {
    setMail(e.target.value)
  }

  const onSubmit = formProps => {
    props.signin(formProps, () => {
      this.props.history.push('/admin');
    });
  };
  const { handleSubmit, pristine, reset, submitting } = props
  return (
    <div className="marimi">
      <Modal
        isOpen={modalIsOpen}
        toggle={closeModal}
        className="modal"
        fade={true}
        style={{ opacity: 5, display:"block"  }}
      >
        {sendMail === false ?
          <div>
            <ModalHeader ><div style={{ fontSize: 25 }}>Reset password</div></ModalHeader>
            <ModalBody>

              <p> Insert your e-mail</p>
              <Input
                id="exampleEmail"
                name="email"
                placeholder="E-mail"
                type="email"
                onChange={(e) => changeMail(e)}
              />
              {emailValidation === true ?
                <div style={{ display: "flex" }} >
                  <img src={mainLogo} style={{ width: "16px", height: "16px" ,marginTop:"13px"}} />
                  <p style={{ marginTop: '10px', color: 'red' }}>Please insert a valid e-mail!</p>
                </div>
                : null}

            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => resetPassword()}>Send e-mail</Button>{' '}
              <Button color="danger " onClick={() => closeModal()}>Cancel</Button>
            </ModalFooter>
          </div>
          :
          <div>
            <ModalHeader ><div style={{ fontSize: 25 }}>Reset password</div></ModalHeader>
            <ModalBody>
              <p> Please check your e-mail for your new password!</p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => closeModal()}>Ok</Button>
            </ModalFooter>
          </div>}
      </Modal>


      <Card className="bg-secondary shadow border-0 " >
        <center style={{ marginTop: 30 }}> Sign in with credentials </center>
        <CardBody className="px-lg-5 py-lg-5">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup className="mb-3">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <i className="ni ni-circle-08" style={{ marginTop: "11px", marginLeft: "8px", marginRight: "8px" }} />
                <Field
                  className='form-control'
                  name='username'
                  type='text'
                  component={renderField}
                  label="Username"
                  validate={required}
                />
              </div>
            </FormGroup>
            <FormGroup>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <i className="ni ni-lock-circle-open" style={{ marginTop: "11px", marginLeft: "8px", marginRight: "8px" }} />
                <Field
                  className='form-control'
                  name='password'
                  component={renderField}
                  validate={required}
                  type='password'
                  label='Password'
                />
              </div>
            </FormGroup>
            
            {props.errorMessage ?
              <div style={{ color: 'red', textAlign: 'center' }}>Username or password invalid! </div>
              : null}
            <div className="text-center">
              <Button className="my-4" color="primary" type="submit" disabled={submitting}>
                Sign in
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card >
      <div>
        <Row className="mt-3">
          <Col xs="6" >
            <a
              className="text-light"
              onClick={(e) => openModal()}
              style={{ marginLeft: "5%" }}
            >
              <small>Forgot password?</small>
            </a>
          </Col>
          <Col className="text-right" xs="6">
            <NavLink to="/auth/register" activeClassName="text-light">
              <a className="text-light" style={{ marginRight: "5%" }}>
                <small>Create new account</small>
              </a>
            </NavLink>
          </Col>
        </Row>
      </div>
    </div>

  )
}


function mapStateToProps(state) {
  return { errorMessage: state.auth.errorMessage };
}

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({ form: 'signin' })
)(Logins);
