// ver: 0.92 19.03.2021
// author: Cristian Vasiliu (Max4Prof)

import React, { Component }  from 'react';

// reactstrap components
import {
  Row,
  Col,
  Container,
  Label,
} from "reactstrap";

class BotGetStrategy extends Component {

    constructor() {
        super();
        this.state = {
            isLoaded: false,
        }
    }

    componentDidMount = () => {
        ////console.log("componentDidMOunt for BotStrategy")
        ////console.log(this.props.conf)
        if(typeof this.props.conf !== 'undefined') {
            this.setState({isLoaded: true})
        } 
    }

    render () {

        const { conf } = this.props;

        const StrategyConfiguration = Object.keys(conf).map((key,index) => {
            return [
                <dl>
                    <Row>
                        <Col>
                            <Label>
                                <div>
                                    {key}
                                </div>
                            </Label>
                        </Col>
                        <Col>
                            {(typeof conf[key] !== 'object')? conf[key] :null}
                        </Col>
                    </Row>
                    {
                    (typeof conf[key] === 'object') ?
                        Object.keys(conf[key]).map((k,i) => {
                    
                            return(
                                <Row>
                                    <Col>
                                        <h4>
                                            <span 
                                                style={{paddingLeft: '20px'}}> {  k}:
                                            </span>
                                        </h4>
                                    </Col>
                                    <Col>
                                        {String(conf[key][k])}
                                    </Col>
                                </Row> 
                            )
                        })
                    :null
                    }
                </dl>                   
            ];
        });
        

        return(
            <div>
                { this.state.isLoaded &&
                <Container>
                    {StrategyConfiguration}
                </Container>
                }
            </div>
        )
    }
}

export default BotGetStrategy;
