//import Login from "components/Footers/AuthFooter";
import React, { Component }  from 'react';
//import { reduxForm, Field } from 'redux-form';
//import { compose } from 'redux';
//import { connect } from 'react-redux';
//import * as actions from '../../../../actions'
//import { Link, NavLink } from "react-router-dom";
//import Header from '../Headers/Header';

// reactstrap components
import {
//  Button,
//  Card,
//  CardHeader,
//  CardBody,
//  FormGroup,
//  Form,
//  Input,
//  InputGroupAddon,
//  InputGroupText,
//  InputGroup,
  Row,
  Col,
  Container,
//  DropdownMenu,
//  DropdownItem,
//  UncontrolledDropdown,
//  DropdownToggle,
//  Dropdown,
//  Table,
//  Label,
} from "reactstrap";

//import Select from 'react-select';

class BotGetMarket extends Component {

    render () {
        return(
            <div>
            <Container>
            <Row>
                <Col>
                    <h4>Exchanges:</h4>
                </Col>
                <Col>
                    <div>{this.props.exchange}</div>
                </Col>
            
            </Row>
            <Row className="mt-2">
                <Col>
                    <h4>Currency:</h4>
                </Col>
                <Col>
                    <div>{this.props.currency}</div>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col>
                    <h4>Assest:</h4>
                </Col>
                <Col>
                    <div>{this.props.asset}</div>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col>
                    <h4>Type:</h4>
                </Col>
                <Col>
                    <div>{this.props.type}</div>
                </Col>
            </Row>

            </Container>

            </div>
        )
    }
}

export default BotGetMarket;


