/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
// node.js library that concatenates classes (strings)
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../actions';
import { withRouter } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import axios from 'axios';

import Header from "components/Headers/Header.js";


const Index = (props) => {

  const [countBoti, setCountBoti] = useState(0);
  const [countWatcheri, setCountWatcheri] = useState(0);
  const [countActiveTraderi, setCountActiveTraderi] = useState(0);
  const [profit, setProfit] = useState(0);

  useEffect(async () => {
    console.log(props)
    const response = await axios.get(
      process.env.REACT_APP_URL_Gekko + '/api/gekko/' + props.user_credentials.user_id + '/' + props.user_credentials.bot_id + '/api/gekkos',
      {
        headers: {
          "Authorization": " Bearer " + props.authenticated,
          "content-type": "application/json"
        }
      }

    ).then(response => {

      var myData = Object.keys(response.data.live).map(key => {
        return response.data.live[key];
      })
      var __countBoti = 0;
      var __countWatcheri = 0;
      var __countActiveTraderi = 0;
      var __profit = 0;
      myData.forEach((item => {
        if (item.logType === "watcher") {
          __countBoti++;
          __countWatcheri++;
        }
        if (item.logType === "papertrader") {
          if (item.config.type === "trader bot") {
            __countBoti++;
            __countActiveTraderi++;
            __profit = __profit + item.events.latest.performanceReport.profit
          }
        }
      }))
      setCountBoti(__countBoti);
      setCountWatcheri(__countWatcheri)
      setCountActiveTraderi(__countActiveTraderi)
      setProfit(__profit)
    }).catch(err => {
      console.log(err.response)
      if (err.response) {
        if (err.response.status > 401) { console.log("test") }
      }
    })



  }, []);


  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row className="mt-5">

          <Col className="mb-5 mb-xl-0" xl="3">

            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0"> Number of Bots</h3>
                  </div>
                  <div>
                    <i className="fas fa-screwdriver text-success fa-2x"></i>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {countBoti}
              </CardBody>
            </Card>


          </Col>

          <Col className="mb-5 mb-xl-0" xl="3">

            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0"> Number of Watchers</h3>
                  </div>
                  <div>
                    <i className="fas fa-screwdriver text-success fa-2x"></i>
                  </div>

                </Row>
              </CardHeader>
              <CardBody>
                {countWatcheri}
              </CardBody>
            </Card>


          </Col>
          <Col className="mb-5 mb-xl-0" xl="3">

            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Active Trading Bots</h3>
                  </div>
                  <div>
                    <i className="fas fa-screwdriver text-success fa-2x"></i>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {countActiveTraderi}
              </CardBody>
            </Card>


          </Col>

          <Col className="mb-5 mb-xl-0" xl="3">

            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Profit</h3>
                  </div>
                  <div>
                    <i className="fas fa-screwdriver text-success fa-2x"></i>
                  </div>

                </Row>
              </CardHeader>
              <CardBody>
                {profit > 0 ? <div style={{ color: 'green' }}> <i className="fa fa-arrow-up" /> {profit} BTC</div> : <div style={{ color: 'red' }}> <i className="fa fa-arrow-down" /> {profit} BTC</div>}
              </CardBody>
            </Card>


          </Col>
        </Row>




        <Row className="mt-5">

          <Col className="mb-5 mb-xl-0" xl="6">

            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0"></h3>
                  </div>
                  <div>
                    <i className="fas fa-screwdriver text-success fa-2x"></i>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <iframe src="https://widget.coinlib.io/widget?type=full_v2&theme=light&cnt=5&pref_coin_id=1506&graph=yes" width={"100%"} height={"535px"}
                  style={{ border: 0, margin: 0, padding: 0 }}>
                </iframe>
              </CardBody>
            </Card>


          </Col>

          <Col className="mb-5 mb-xl-0" xl="6">

            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0"></h3>
                  </div>
                  <div>
                    <i className="fas fa-screwdriver text-success fa-2x"></i>
                  </div>
                  {/*<div className="col">
  <i className="fas fa-btc text-success fa-2x"></i>
</div>*/}
                </Row>
              </CardHeader>
              <CardBody>
                <iframe src="https://widget.coinlib.io/widget?type=chart&theme=light&coin_id=859&pref_coin_id=1505" width="100%" height="536px" scrolling="auto" marginWidth="0" marginHeight="0" frameBorder="0" border="0" style={{ border: 0, margin: 0, padding: 0, lineHeight: "14px" }}></iframe>
              </CardBody>
            </Card>


          </Col>
        </Row>
      </Container>
    </>
  );
};

function mapStateToProps(state) {
  return { authenticated: state.auth.authenticated, user_credentials: state.auth.user_credentials };
}


export default compose(
  connect(mapStateToProps, actions)
)(withRouter(Index));
