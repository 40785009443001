//import Login from "components/Footers/AuthFooter";
import React, { Component } from 'react';
//import { reduxForm, Field } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
//import { Link, NavLink } from "react-router-dom";
import { withRouter } from "react-router-dom";
import axios from 'axios';

//import { BotsSummay } from './BotsSummary';
//import { BotsAdd } from './BotsAdd';
//import { BotsGet } from './BotsGet';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    Container,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
import Header from "components/Headers/Header";
import BotsMarketGraphic from "components/Bots/BotsGet/BotsMarketGraphic"
import BotsTradesHistory from "components/Bots/BotsGet/BotsTradesHistory"
import BotGetMarket from "components/Bots/BotsGet/BotGetMarket"
import BotGetRuntime from "components/Bots/BotsGet/BotGetRuntime"
import BotGetStrategy from "components/Bots/BotsGet/BotGetStrategy"
import BotGetProfit from "components/Bots/BotsGet/BotGetProfit"


class BotsGet extends Component {
    constructor(props) {
        ////console.log("Constructor de Bots")
        super(props);
        this.state = {
            bot: '',
            isFetching: false,
            isLoaded: false,
            modal: false,
        }
    }

    componentDidMount() {
        //console.log("componentDidMount for BotsGet")
        //console.log(this.props.location.bot_id)
        this.setState({ bot: this.props.location.bot_id })

        const fetchBotsData = async () => {
            ////console.log("FetchBotsData")
            try {
                this.setState({ isFetching: true, isLoaded: false })

                const response = await axios.get(
                    process.env.REACT_APP_URL_Gekko + '/api/gekko/' + this.props.user_credentials.user_id + '/' + this.props.user_credentials.bot_id + '/api/gekkos',
                    {
                        headers: {
                            "Authorization": " Bearer " + this.props.authenticated,
                            "content-type": "application/json"
                        }
                    }

                ).catch(err => {
                    if (err.response.status === 401) { console.log("test") }
                })

                this.setState({ bot: response.data.live[this.props.location.bot_id], isFetching: false, isLoaded: true })

            } catch {
                this.setState({ isFetching: false, isLoaded: false })
            }
        }

        if (this.state.bot === '') {
            ////console.log("Bots e gol! Iau datele acum!")

            fetchBotsData();
            //console.log(this.state.bot)
            //console.log(this.state.bot.events)
        }

    }

    buttonPressYes = () => {
        this.setState({ modal: !this.state.modal })
        const stopBot = async () => {
            //console.log(this.state.bot['id'])
            try {
                //this.setState({isFetching: true})
                const headers = {
                    "Authorization": "Bearer " + this.props.authenticated,
                    "content-type": "application/json"
                };

                const jsondata = {
                    id: this.state.bot['id']
                }

                await axios.post(
                    process.env.REACT_APP_URL_Gekko + '/api/gekko/' + this.props.user_credentials.user_id + '/' + this.props.user_credentials.bot_id + '/api/stopGekko',
                    jsondata,
                    { headers: headers }).then(res => {
                        //console.log(res.data)
                        if (res.status === 401) { console.log("test") }

                    }
                    ).catch(err => {
                        if (err.response.status === 401) { console.log("test") }
                    })


            } catch {
                //alert ("Something went wrong!")
                //this.setState({isFetching: false})
            }
        }
        stopBot()
        this.props.history.push('/admin/service/bots')

    }

    toggle = () => {
        //console.log("Stop Gekko in toggle")
        //console.log("reversul modal state-ului este: " + !this.state.modal)
        this.setState({ modal: !this.state.modal })
        //console.log("Modal state: " + this.state.modal)

    }


    render() {
        return (
            <div>
                <Header />
                <Container className="mt--7" fluid>
                    <Row>
                        <Col className="mb-5 mb-xl-0" xl="8">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Runtime</h3>
                                        </div>
                                        <div>
                                            <i className="fas fa-spinner text-success fa-2x fa-pulse"></i>
                                        </div>
                                        {/*<div className="col">
                                            <i className="fas fa-btc text-success fa-2x"></i>
                                        </div>*/}
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    {this.state.isLoaded ?
                                        <BotGetRuntime
                                            watching_since={this.state.bot['start']}
                                            receive_data_until={this.state.bot['lastUpdate']}
                                            amount_of_trades={(typeof this.state.bot.events.latest.performanceReport !== 'undefined') ? this.state.bot.events.latest.performanceReport['trades'] : "0"}
                                            candle_size={this.state.bot.config.tradingAdvisor.candleSize}
                                            history_size={this.state.bot.config.tradingAdvisor.historySize}
                                            bot_type={this.props.location.bot_type}
                                        />
                                        : null}
                                </CardBody>
                            </Card>
                        </Col>

                        <Col className="mb-5 mb-xl-0" xl="4">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Markets</h3>
                                        </div>
                                        <div>
                                            <i className="fas fa-handshake text-success fa-2x"></i>
                                        </div>
                                        {/*<div className="col">
                                            <i className="fas fa-btc text-success fa-2x"></i>
                                        </div>*/}
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    {this.state.isLoaded ?
                                        <BotGetMarket
                                            exchange={this.state.bot.config.watch['exchange']}
                                            currency={this.state.bot.config.watch['currency']}
                                            asset={this.state.bot.config.watch['asset']}
                                            type={this.state.bot.config.type}
                                        />
                                        : null}
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>

                    {
                        (this.state.isLoaded && this.state.bot.config.type !== "market watcher") ?
                            <Row className="mt-5">

                                <Col className="mb-5 mb-xl-0" xl="6">
                                    <Card className="shadow">
                                        <CardHeader className="border-0">
                                            <Row className="align-items-center">
                                                <div className="col">
                                                    <h3 className="mb-0">Strategy Configuration</h3>
                                                </div>
                                                <div>
                                                    <i className="fas fa-wrench text-success fa-2x"></i>
                                                </div>
                                                {/*<div className="col">
                                            <i className="fas fa-btc text-success fa-2x"></i>
                                        </div>*/}
                                            </Row>
                                        </CardHeader>
                                        <CardBody>
                                            <BotGetStrategy
                                                conf={this.state.bot.config[this.state.bot.config.tradingAdvisor['method']]}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>


                                <Col className="mb-5 mb-xl-0" xl="6">
                                    <Card className="shadow">
                                        <CardHeader className="border-0">
                                            <Row className="align-items-center">
                                                <div className="col">
                                                    <h3 className="mb-0">Profit Report</h3>
                                                </div>
                                                <div>
                                                    <i className="fas fa-hand-holding-usd text-success fa-2x"></i>
                                                </div>
                                                {/*<div className="col">
                                            <i className="fas fa-btc text-success fa-2x"></i>
                                        </div>*/}
                                            </Row>
                                        </CardHeader>
                                        <CardBody>
                                            {(typeof this.state.bot.events.latest.performanceReport !== 'undefined') ?
                                                <BotGetProfit
                                                    startBalance={this.state.bot.events.latest.performanceReport['startBalance']}
                                                    balance={this.state.bot.events.latest.performanceReport['balance']}
                                                    endPrice={this.state.bot.events.latest.performanceReport['endPrice']}
                                                    startPrice={this.state.bot.events.latest.performanceReport['startPrice']}
                                                    currency={this.state.bot.config.watch['currency']}
                                                    market={this.state.bot.events.latest.performanceReport['market']}
                                                    profit={this.state.bot.events.latest.performanceReport['profit']}
                                                    relativeProfit={this.state.bot.events.latest.performanceReport['relativeProfit']}
                                                    alpha={this.state.bot.events.latest.performanceReport['alpha']}

                                                /> :
                                                <BotGetProfit />
                                            }
                                        </CardBody>
                                    </Card>
                                </Col>


                            </Row>
                            : null}

                    <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Market Chart</h3>
                                        </div>
                                        <div className="col-md-6 text-right">
                                            <i className="fas fa-chart-line text-success fa-2x"></i>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    {this.state.isLoaded ?
                                        <div>
                                           
                                            <BotsMarketGraphic
                                                exchange={this.state.bot.config.watch['exchange']}
                                                currency={this.state.bot.config.watch['currency']}
                                                asset={this.state.bot.config.watch['asset']}
                                                start={this.state.bot['start']}
                                                stop={this.state.bot['stopped']}
                                                tradeCompleted={(typeof this.state.bot.events.tradeCompleted !== 'undefined') ?
                                                this.state.bot.events.tradeCompleted : "empty"
                                            }
                                            />
                                        </div> : null}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {
                        (this.state.isLoaded && this.state.bot.config.type !== "market watcher") ?
                            <Row className="mt-5">
                                <Col className="mb-5 mb-xl-0" xl="12">
                                    <Card className="shadow">
                                        <CardHeader className="border-0">
                                            <Row className="align-items-center">
                                                <div className="col">
                                                    <h3 className="mb-0">Trades History</h3>
                                                </div>
                                                <div className="col-md-6 text-right">
                                                    <i className="fas fa-balance-scale text-success fa-2x"></i>
                                                </div>
                                            </Row>
                                        </CardHeader>
                                        <CardBody>
                                            {this.state.isLoaded ?

                                                <BotsTradesHistory
                                                    roundtrip={(typeof this.state.bot.events.roundtrip !== 'undefined') ?
                                                        this.state.bot.events.roundtrip : "empty"
                                                    }
                                                />
                                                : null}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row> : null
                    }

                    {this.state.isLoaded &&
                        <Row className="mt-5">
                            <Col className="mb-5 mb-xl-0" xl="12">
                                <Card className="shadow">
                                    <CardHeader className="border-0">
                                        <Row className="align-items-center">
                                            <div className="col">
                                                <h3 className="mb-0">Stop trading bot / paper tradingbot / market watcher</h3>
                                            </div>
                                            <div className="col text-right">
                                                <Button
                                                    color="primary"
                                                  
                                                    onClick={() => this.toggle()}
                                                    size="sm"
                                                >
                                                    Stop
                                                </Button>
                                                <Modal
                                                    isOpen={this.state.modal}
                                                    toggle={this.toggle}
                                                    className="modal"

                                                    fade={false}
                                                    style={{ opacity: 1, display: "block" }}
                                                >
                                                    <ModalHeader toggle={this.toggle}>Bots handle</ModalHeader>
                                                    <ModalBody>
                                                        Are you sure you want to stop this {this.state.bot.config.type}?
                                                    </ModalBody>
                                                    <ModalFooter>
                                                        <Button color="primary" onClick={() => this.buttonPressYes()}>Yes</Button>{' '}
                                                        <Button color="secondary" onClick={() => this.toggle()}>No</Button>
                                                    </ModalFooter>
                                                </Modal>


                                            </div>
                                        </Row>
                                    </CardHeader>
                                </Card>
                            </Col>
                        </Row>
                    }

                </Container>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return { authenticated: state.auth.authenticated, user_credentials: state.auth.user_credentials };
}


export default compose(
    connect(mapStateToProps, actions)
)(withRouter(BotsGet));

  //export default withRouter(BotsGet);