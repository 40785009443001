//import Login from "components/Footers/AuthFooter";
import React, { Component } from 'react';
//import { reduxForm, Field } from 'redux-form';
//import { compose } from 'redux';
//import { connect } from 'react-redux';

//import { Link, NavLink } from "react-router-dom";


// reactstrap components
import {
    //  Button,
    //  Card,
    //  CardHeader,
    //  CardBody,
    //  FormGroup,
    //  Form,
    //  Input,
    //  InputGroupAddon,
    //  InputGroupText,
    //  InputGroup,
    Row,
    Col,
    Spinner,
    Container,
} from "reactstrap";




class BacktestResultData extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: null,
            isLoaded: null
        }

    }
    componentDidMount() {
        this.setState({ data: this.props.data, isLoaded: this.props.isLoaded })
    }

    render() {

        return (
            <div>

                <Container>
                    {this.state.data && this.state.isLoaded ?
                        <div>
                            <Row>
                                <Col className="mb-5 mb-xl-0" xl="5">

                                    <Row>
                                        <Col><b>START TIME</b></Col>
                                        <Col><div className="row justify-content-end">{this.props.data.startTime}</div></Col>
                                    </Row>
                                    <Row>
                                        <Col><b>END TIME</b></Col>
                                        <Col><div className="row justify-content-end">{this.props.data.endTime}</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col><b>TIMESPAN</b></Col>
                                        <Col><div className="row justify-content-end">{this.props.data.timespan}</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col><b>START PRICE</b></Col>
                                        <Col><div className="row justify-content-end">{this.props.data.startPrice}</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col><b>END PRICE</b></Col>
                                        <Col><div className="row justify-content-end">{this.props.data.endPrice}</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col><b>MARKET</b>
                                        </Col>
                                        <Col><div className="row justify-content-end">{this.props.data.market}%</div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="mb-5 mb-xl-0" xl="1"></Col>
                                <Col className="mb-20 mb-xl-0" xl="5">
                                    <Row>
                                        <Col><b>AMOUNT OF TRADES</b></Col>
                                        <Col><div className="row justify-content-end">{this.props.data.trades}</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col><b>SHARPE RATIO</b></Col>
                                        <Col><div className="row justify-content-end">{this.props.data.sharpe}</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col><b>START BALANCE</b></Col>
                                        <Col><div className="row justify-content-end">{this.props.data.startBalance}</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col><b>FINAL BALANCE</b></Col>
                                        <Col><div className="row justify-content-end">{this.props.data.balance}</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col><b>SIMULATED PROFIT</b></Col>
                                        <Col>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                        </Col>
                                        <Col>
                                            {this.props.data.relativeProfit < 0 ?
                                                <div className="row justify-content-end">
                                                    <i className="fas fa-arrow-down text-warning mr3" />
                                                    <b style={{ color: 'red' }}>
                                                        <span style={{ paddingLeft: '10px' }}>{this.props.data.relativeProfit}%</span>
                                                    </b></div>
                                                :
                                                <div className="row justify-content-end">
                                                    <i className="fas fa-arrow-up text-success mr3" />
                                                    <b style={{ color: 'green' }}>
                                                        <span style={{ paddingLeft: '10px' }}>{this.props.data.relativeProfit}%</span>
                                                    </b></div>}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        : <center><Spinner/></center>}
                </Container>

            </div>
        )
    }
}

export default BacktestResultData;


