//import Login from "components/Footers/AuthFooter";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

//import { reduxForm, Field } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from './../../actions';
//import { Link, NavLink } from "react-router-dom";

//import { DateTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
import Header from '../Headers/Header'
// reactstrap components
import {
    //  Button,
      Card,
      CardHeader,
      CardBody,
    //  Form,
    //  Input,
    //  InputGroupAddon,
    //  InputGroupText,
    //  InputGroup,
    Row,
    Col,
    Table,
    Container,
    //  FormGroup, 
    //  Label, 
    Spinner
    //  FormText
} from "reactstrap";
import { URL_instanta_gekko } from "./../../config";




//var DatePicker = require("reactstrap-date-picker");

function differenceBetweenDates(date_future, date_now) {
    var d = Math.abs(date_future - date_now);                           // delta
    var r = {};                                                                // result
    var s = {                                                                  // structure
        year: 31536000,
        month: 2592000,
        week: 604800, // uncomment row to ignore
        day: 86400,   // feel free to add your own row
        hour: 3600,
        minute: 60,
        second: 1
    };

    Object.keys(s).forEach(function (key) {
        r[key] = Math.floor(d / s[key]);
        d -= r[key] * s[key];
    });

    // for example: {year:0,month:0,week:1,day:2,hour:34,minute:56,second:7}
    //console.log(r);
    var response = "";
    if (r.year > 0) { response = response + r.year + " years, " }
    if (r.month > 0) { response = response + r.month + " months, " }
    if (r.day > 0) { response = response + r.day + " days, " }
    if (r.hour > 0) { response = response + r.hour + " hours, " }
    if (r.minute > 0) { response = response + r.minute + " minutes" }
    return response
}
function getDateAndHours(timestamp) {
    var timestamp1 = timestamp * 1000;
    var date = new Date(timestamp1)
    //console.log(date.getFullYear())
    return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes()
}

class BotsDatasetsView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            value: new Date().toISOString(),
            datasets: [],
            loaded: false
        }
    }

    handleChange(value, formattedValue) {
        this.setState({
            value: value, // ISO String, ex: "2016-11-19T12:00:00.000Z"
            formattedValue: formattedValue // Formatted String, ex: "11/19/2016"
        })
        //console.log("Noua Valoare " + this.state.value)
        //console.log("Noua valoare formatata " + this.state.formattedValue)
    }
    componentDidMount() {
        var myHeaders = new Headers();
        myHeaders.append("accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + this.props.authenticated);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({})
        };

        fetch( URL_instanta_gekko + "/api/scansets", requestOptions)
            .then(response => {
                if (response.status === 401) { console.log("test") }
                else { return response.text() }
            }
            )
            .then(response => JSON.parse(response))
            .then(result => {
                var finalArr = [];
                result.datasets.map(item => {
                    //console.log(item)
                    var duration = '-';
                    var range = {
                        to: null,
                        from: null
                    }
                    if (item.ranges.length > 0) {
                        var arr = [];

                        item.ranges.map(itemRange => {
                            duration = differenceBetweenDates(itemRange.to, itemRange.from)
                            var fromDate = getDateAndHours(itemRange.from);
                            var toDate = getDateAndHours(itemRange.to)
                            range = {
                                from: fromDate,
                                to: toDate
                            }
                            item = {
                                ...item,
                                ranges: range,
                                duration
                            }
                            arr.push(item)
                        })
                        arr.map(item => {
                            finalArr.push(item)
                        })

                    }

                })
                this.setState({ datasets: finalArr, loaded: true })
            }
            )
            .catch(error => console.log('error', error));
    }

    //componentDidUpdate() {
    // Access ISO String and formatted values from the DOM.
    //  var hiddenInputElement = document.getElementById("example-datepicker");
    //  //console.log(hiddenInputElement.value); // ISO String, ex: "2016-11-19T12:00:00.000Z"
    //  //console.log(hiddenInputElement.getAttribute('data-formattedvalue')) // Formatted String, ex: "11/19/2016"
    //}

    listaDatasets() {
        var listDataSets = this.state.datasets.map((d, key) =>
            <tr key={key}>
                <td className="text-center">{d.exchange}</td>
                <td className="text-center">{d.currency}</td>
                <td className="text-center">{d.asset}</td>
                <td className="text-center">{d.ranges.from}</td>
                <td className="text-center">{d.ranges.to}</td>
                <td className="text-center">{d.duration}</td>
            </tr>
        );
        return listDataSets
    }
    render() {
        return (
            <div>
                <Header />
                <Container className="mt--7" fluid>
                <Row>
                    <Col className="mb-5 mb-xl-0" xl="12">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <h3 className="mb-0">Available Data Sets</h3>
                                    </div>
                                    <div className="col-md-6 text-right">
                                        <i className="fas fa-database text-success fa-2x"></i>
                                    </div>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {this.state.loaded === true ?
                                    <Table className="align-items-center table-flush" responsive>
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col" className="text-center">Exchange</th>
                                                <th scope="col" className="text-center">Currency</th>
                                                <th scope="col" className="text-center">Assets</th>
                                                <th scope="col" className="text-center">From</th>
                                                <th scope="col" className="text-center">To</th>
                                                <th scope="col" className="text-center">Duration</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.datasets.length > 0 ? this.listaDatasets() : null}
                                        </tbody>
                                    </Table> :
                                    <center>
                                        <Spinner color="success" />
                                    </center>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                </Container>

            </div>
        )
    }
}


function mapStateToProps(state) {
    return { authenticated: state.auth.authenticated, user_credentials: state.auth.user_credentials };
}

export default compose(
    connect(mapStateToProps, actions)
)(withRouter(BotsDatasetsView));
