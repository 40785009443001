//import Login from "components/Footers/AuthFooter";
import React, { Component } from "react";
import axios from 'axios';
import moment from 'moment';

//import { reduxForm, Field } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
//import { Link, NavLink } from "react-router-dom";

//import { DateTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";

// reactstrap components
import {
  //  Button,
  //  Card,
  //  CardHeader,
  //  CardBody,
  //  Form,
  Input,
  //  InputGroupAddon,
  //  InputGroupText,
  //  InputGroup,
  //  Row,
  //  Col,
  Table,
  Spinner,
  //  FormGroup, 
  //  Label, 
  //  FormText,
} from "reactstrap";

//var DatePicker = require("reactstrap-date-picker");

/*
const DataSets = [
    {
        exchange: "kraken",
        currency: "XBT",
        asset: "ETH",
        from: "2020-08-15 15:18",
        to: "2021-02-15 15:16",
        duration: "6 months, 1 day, 8 hours, 58 minutes",
    },
    {
        exchange: "poloniex",
        currency: "USDT",
        asset: "BTC",
        from: "2020-11-12 21:52",
        to: "2021-02-25 16:25",
        duration: "3 months, 1 week, 6 days, 11 hours",
    },
    {
        exchange: "binance",
        currency: "BTC",
        asset: "ETH",
        from: "	2021-02-21 17:21",
        to: "2021-02-25 16:25",
        duration: "3 days, 23 hours, 4 minutes",
    },
]
*/
class BotsDatasets extends Component {

  constructor(props) {
    super(props)
    this.state = {
      value: new Date().toISOString(),
      isLoaded: false,
      isFetching: false,
      datasets: [],
      enabledDataSet: '',
      datasetValue: {
        watch: {
          exchange: "",
          currency: "",
          asset: ""
        },
        backtest: {
          daterange: {
            from: '',
            to: ''
          }
        },
      }
    }
    ////console.log("Valoare initiala " + this.state.value)
  }

  handleChange(value, formattedValue) {
    this.setState({
      value: value, // ISO String, ex: "2016-11-19T12:00:00.000Z"
      formattedValue: formattedValue // Formatted String, ex: "11/19/2016"
    })
    ////console.log("Noua Valoare " + this.state.value)
    ////console.log("Noua valoare formatata " + this.state.formattedValue)
  }

  componentDidMount() {
    //console.log("componentDidMound from DataSets")
    //console.log(this.state.datasets)
    if (this.state.datasets.length === 0) {
      //console.log("Lets get Datasets")
      this.fetchDateRanges()
    }
  }

  async fetchDateRanges() {
    //console.log(" fetchDateRanges")
    try {
      this.setState({ isFetching: true, isLoaded: false })
      const headers = {
        "Authorization": "Bearer " + this.props.authenticated,
        "content-type": "application/json"
      };
      const jsondata = {}

      await axios.post(
        process.env.REACT_APP_URL_Gekko + '/api/gekko/' + this.props.user_credentials.user_id + '/' + this.props.user_credentials.bot_id + '/api/scansets',
        jsondata,
        { headers: headers })
        .then(res => {
          //console.log(res)
          if (res.status === 401) { console.log("test") }
          this.setState({ datasets: res.data['datasets'], isFetching: false, isLoaded: true })
        }
        ).catch(err => {
          if (err.response.status === 401) { console.log("test") }
        })
    } catch {
      this.setState({ isFetching: false })
    }
  }
  //componentDidUpdate() {
  // Access ISO String and formatted values from the DOM.
  //  var hiddenInputElement = document.getElementById("example-datepicker");
  //  //console.log(hiddenInputElement.value); // ISO String, ex: "2016-11-19T12:00:00.000Z"
  //  //console.log(hiddenInputElement.getAttribute('data-formattedvalue')) // Formatted String, ex: "11/19/2016"
  //}

  SelectDataRange = (event, DataRangeExchange, DataRangeCurrency, DataRangeAsset, DataRangeFrom, DataRangeTo) => {
    //console.log(event.target.value)
    ////console.log(String(id))

    let DataRangeJson = this.state.datasetValue
    DataRangeJson.watch.exchange = DataRangeExchange
    DataRangeJson.watch.currency = DataRangeCurrency
    DataRangeJson.watch.asset = DataRangeAsset
    DataRangeJson.backtest.daterange.from = this.formatDate(DataRangeFrom)
    DataRangeJson.backtest.daterange.to = this.formatDate(DataRangeTo)
    this.setState({
      enabledDataSet: event.target.value,
      datasetValue: DataRangeJson
    })
    //console.log(DataRangeJson)
    this.props.updateConf(DataRangeJson)
    ////console.log(DataRangeJson.backtest.daterange.from)
    ////console.log(this.state.enabledDataSet)
    ////console.log(this.state.datasetValue)
  }

  onChangeStartDate = (event) => {

    if (moment(moment(event.target.value).format("YYYY-MM-DD hh:mm"), "YYYY-MM-DD hh:mm", true).isValid()) {
      //console.log(event.target.value)
      let StartDate = this.state.datasetValue
      //make sure that StartDate is ok 
      StartDate.backtest.daterange.from = moment(event.target.value, "YYYY-MM-DD hh:mm").format("YYYY-MM-DDThh:mm") + 'Z'
      //console.log(moment(event.target.value, "YYYY-MM-DD hh:mm").format("YYYY-MM-DDThh:mm") + 'Z')
      this.setState({ datasetValue: StartDate })
      this.props.updateConf(StartDate)
    } else {
      alert("Wrong data format!")
    }
  }

  onChangeEndDate = (event) => {
    if (moment(moment(event.target.value).format("YYYY-MM-DD hh:mm"), "YYYY-MM-DD hh:mm", true).isValid()) {
      //console.log(event.target.value)
      let EndDate = this.state.datasetValue
      //make sure that StartDate is ok 
      EndDate.backtest.daterange.to = moment(event.target.value, "YYYY-MM-DD hh:mm").format("YYYY-MM-DDThh:mm") + 'Z'
      //console.log(moment(event.target.value, "YYYY-MM-DD hh:mm").format("YYYY-MM-DDThh:mm") + 'Z')
      this.setState({ datasetValue: EndDate })
      this.props.updateConf(EndDate)
    } else {
      alert("Wrong data format!")
    }
  }

  perioadCalculation = (StartDate, EndDate) => {
    //console.log("function perioadCaluculation")
    let diff = moment.unix(EndDate).diff(moment.unix(StartDate));
    let diffDuration = moment.duration(diff);
    let perioad = ''
    if (diffDuration.minutes()) { perioad = diffDuration.minutes() + " minutes" }
    if (diffDuration.hours()) { perioad = diffDuration.hours() + " hours " + perioad }
    if (diffDuration.days()) { perioad = diffDuration.days() + " days " + perioad }
    if (diffDuration.months()) { perioad = diffDuration.months() + " months " + perioad }
    if (diffDuration.years()) { perioad = diffDuration.years() + " years " + perioad }
    ////console.log(perioad)
    return perioad
  }

  formatDate(timestamp) {
  var datestring = new Date(timestamp * 1000).toISOString();
  var removesubstr=datestring.replaceAll(':00.000Z', ':00Z');
    return removesubstr
  }

  render() {
    const { isFetching,
      isLoaded,
      datasets
    } = this.state

    var id = 0

    const listAllDataSets = datasets.map((d, i) => {
      if (!i) { id = 0 }
      return [
        d.ranges.map((key, index) =>
          <tr key={index}>
            <td className="align-items-center">
              <div className='font-small d-flex align-items-center'>
                <Input
                  type="radio"
                  name="dateRange"
                  id={id++}
                  value={id}
                  onChange={(e) => this.SelectDataRange(e, d.exchange, d.currency, d.asset, key['from'], key['to'])}
                />
              </div>
            </td>
            <td>{d.exchange}</td>
            <td>{d.currency}</td>
            <td>{d.asset}</td>
            <td>
              <Input
                type="datetime"
                //name="endDate"
                id={this.state.enabledDataSet}
                name={this.state.enabledDataSet}
                className="input-group datetime-group-sm"
                placeholder={this.formatDate(key['from'])}
                disabled={!(String(id) === String(this.state.enabledDataSet))}
                defaultValue={this.formatDate(key['from'])}
                bsSize='sm'
                onBlur={(e) => this.onChangeStartDate(e)}
              />
            </td>
            <td>
              <Input
                type="datetime"
                name="endDate"
                id="endDate"
                className="input-group datetime-group-sm"
                placeholder={this.formatDate(key['to'])}
                disabled={!(String(id) === String(this.state.enabledDataSet))}
                defaultValue={this.formatDate(key['to'])}
                bsSize='sm'
                onBlur={(e) => this.onChangeEndDate(e)}
              />
            </td>
            <td>
              {
                this.perioadCalculation(key['from'], key['to'])
              }
            </td>
          </tr>
        )
      ]
    })


    return (
      <div>
        {(!isFetching && isLoaded) ?

          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col" className="text-center"></th>
                <th scope="col" className="text-center">Exchange</th>
                <th scope="col" className="text-center">Currency</th>
                <th scope="col" className="text-center">Assets</th>
                <th scope="col" className="text-center">From</th>
                <th scope="col" className="text-center">To</th>
                <th scope="col" className="text-center">Duration</th>
              </tr>
            </thead>
            <tbody>
              {listAllDataSets}
            </tbody>
          </Table>
          : <center> <Spinner color="success" /></center>
        }
      </div>

    )
  }
}

function mapStateToProps(state) {
  return { authenticated: state.auth.authenticated, user_credentials: state.auth.user_credentials };
}

export default compose(
  connect(mapStateToProps, actions)
)(BotsDatasets);
