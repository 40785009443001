//import Login from "components/Footers/AuthFooter";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
//import { reduxForm, Field } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from './../../actions';
import { Link } from "react-router-dom";
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
    DateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import Header from '../Headers/Header';
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    //  Form,
    Input,
    //  InputGroupAddon,
    //  InputGroupText,
    //  InputGroup,
    Row,
    Col,
    //  Table,
    //  FormGroup, 
    Label,
    //  FormText,
    Container
} from "reactstrap";
// var DatePicker = require("reactstrap-date-picker");



class DataSetImporterAdd extends Component {

    constructor(props) {
        super(props)
        this.state = {
            toDate: new Date(),
            fromDate: new Date(),
            market: null,
            marketArray: [],
            currency: null,
            currencyArray: [],
            asset: null,
            assetArray: [],
            dataTree: [],
            marketSelect: [],
            loading: false,
            currentObj: {}
        }
        ////console.log("Valoare initiala " + this.state.value)
    }



    componentDidMount() {
        this.setState({
            loading: true
        })
        var d = new Date();
        d.setMonth(d.getMonth() - 3);
        this.setState({
            fromDate: d
        })
        var myHeaders = new Headers();
        myHeaders.append("accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + this.props.authenticated);
        myHeaders.append("Content-Type", "application/json");



        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL_Gekko + '/api/gekko/' + this.props.user_credentials.user_id + '/' + this.props.user_credentials.bot_id + "/api/exchanges", requestOptions)
            .then(response => {
                if (response.status === 401) { console.log("test") }
                else { return response.json() }
            })
            .then(result => {
                var arr = [];
                var arrMarketSelect = [];
                result.map((element) => {
                    var objMarketSelect = {
                        value: element.name,
                        label: element.name
                    }
                    arrMarketSelect.push(objMarketSelect)
                    ////
                    var obj = {}
                    obj.name = element.name
                    obj[element.name] = { currencies: element.currencies }
                    obj[element.name].currencies.map(eachcurrency => {
                        var arrAssets = [];
                        element.markets.map(pereche => {
                            if (pereche.pair[0] == eachcurrency) {
                                arrAssets.push(pereche.pair[1])
                            }
                        })
                        obj[element.name][eachcurrency] = arrAssets;
                    })
                    arr.push(obj)
                })
                var firstMarket = arrMarketSelect[0].value;
                var market_1 = arr[0]

                var firstCurrency = arr[0][firstMarket].currencies[0];
                this.setState({
                    dataTree: arr,
                    marketSelect: arrMarketSelect,
                    market: arrMarketSelect[0].name,
                    currency: arr[0][firstMarket].currencies[0],
                    asset: arr[0][firstMarket][firstCurrency][0],
                    assetArray: arr[0][firstMarket][firstCurrency],
                    currencyArray: arr[0][firstMarket].currencies,
                    loading: false
                })

            })
            .catch(error => console.log('error', error));

    }

    formatDateForReq(date){
        var d =date;
        var month=(d.getMonth() + 1)
        var day=d.getDate()
        var hour=d.getHours()
        var min= d.getMinutes()
        if((d.getMonth() + 1)<10) month="0"+(d.getMonth() + 1);
        if( d.getDate()<10) day="0"+ d.getDate();
        if(d.getHours()<10) hour="0"+ d.getHours();
        if( d.getMinutes()<10) min="0"+  d.getMinutes();

        return d.getFullYear() + "-" + month + "-" + day + " " + hour + ":" + min;
    }
    addLocalData() {

        var myHeaders = new Headers();
        myHeaders.append("accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + this.props.authenticated);
        myHeaders.append("Content-Type", "application/json");
        var fromDateFormatted = this.formatDateForReq(this.state.fromDate)
        var toDateFormatted =  this.formatDateForReq(this.state.toDate)
        var raw = JSON.stringify({
            "watch": {
                "exchange": this.state.market.toLowerCase(),
                "currency": this.state.currency,
                "asset": this.state.asset
            },
            "importer": {
                "daterange": {
                    "from": fromDateFormatted,
                    "to": toDateFormatted
                }
            },
            "candleWriter": {
                "enabled": true
            }
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL_Gekko + '/api/gekko/' + this.props.user_credentials.user_id + '/' + this.props.user_credentials.bot_id + "/api/import", requestOptions)
            .then(response => {
                if (response.status === 401) { console.log("test") }
                else { return response.text() }
            })
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }


    selectMarket = (e) => {
        this.setState({ market: e.target.value })
        var marketValue = e.target.value;
        var objMarket = this.state.dataTree.find(e => e.name == marketValue)
        var firstCurrency = objMarket[marketValue].currencies[0];
        this.setState({
            currencyArray: objMarket[marketValue].currencies,
            currentObj: objMarket[marketValue],
            currency: objMarket[marketValue].currencies[0],
            assetArray: objMarket[marketValue][firstCurrency],
            asset: objMarket[marketValue][firstCurrency][0]
        })
    }

    selectCurrency = (e) => {
        this.setState({ currency: e.target.value })
        var currency = e.target.value;
        this.setState({
            assetArray: this.state.currentObj[currency],
            asset: this.state.currentObj[currency][0]
        })
    }

    selectAsset = (e) => {
        this.setState({ asset: e.target.value })

    }




    render() {
        return (
            <div>
                <Header />
                <Container className="mt--7" >
                    <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Import Data Sets</h3>
                                        </div>
                                        <div className="col-md-6 text-right">
                                            <i className="fas fa-cloud-download-alt text-success fa-2x"></i>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Container>
                                        <Row>
                                            <Col style={{display:'center'}}>
                                              
                                                    <Row>
                                                        <Col>
                                                            <Label>Exchanges:</Label>
                                                            <Input type="select" name="select" id="marketSelect" value={this.state.market} onChange={(e) => this.selectMarket(e)}>
                                                                {
                                                                    this.state.marketSelect.map((d, index) => {
                                                                        return (<option key={index}>{d.value}</option>)
                                                                    })
                                                                }
                                                            </Input>
                                                        </Col>
                                                    </Row>
                                                    <div>

                                                        <Row className="mt-2">
                                                            <Col>

                                                                <Label for="exampleSelect">Currency:</Label>
                                                                <Input type="select" name="select" id="exampleSelect" value={this.state.currency} onChange={(e) => this.selectCurrency(e)}>
                                                                    {
                                                                        this.state.currencyArray.map((d, index) => {
                                                                            return (<option key={index}>{d}</option>)
                                                                        })
                                                                    }
                                                                </Input>
                                                            </Col>
                                                            <Col>

                                                                <Label for="exampleSelect">Asset:</Label>
                                                                <Input type="select" name="select" id="exampleSelect" value={this.state.asset} onChange={(e) => this.selectAsset(e)}>
                                                                    {
                                                                        this.state.assetArray.map((d, index) => {
                                                                            return (<option key={index}>{d}</option>)
                                                                        })
                                                                    }
                                                                </Input>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                
                                            </Col>

                                            <Col style={{top:'50%',left:'10%'}}>

                                                <Row >
                                                    <Col>
                                                        <Row>
                                                            <Label for="exampleSelect">From:</Label>
                                                        </Row>
                                                        <Row>
                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                <DateTimePicker value={this.state.fromDate} onChange={(e) => this.setState({ fromDate: e })} format={'yyyy/MM/dd hh:mm'} />
                                                            </MuiPickersUtilsProvider>
                                                        </Row>
                                                    </Col>
                                                </Row>

                                                <Row className="mt-3">
                                                    <Col>

                                                        <Row>
                                                            <Label for="exampleSelect">To:</Label>
                                                        </Row>
                                                        <Row>
                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                <DateTimePicker value={this.state.toDate} onChange={(e) => this.setState({ toDate: e })} format={'yyyy/MM/dd hh:mm'} />
                                                            </MuiPickersUtilsProvider>
                                                        </Row>

                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                    </Container>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row >
                    <Card className="shadow" style={{marginTop:'20px'}}>
                        <CardHeader className="border-0">
                            <Row className="align-items-center">
                                <div className="col">
                                    <h3 className="mb-0">Importing Data</h3>
                                </div>

                                <Link
                                    className="btn btn-primary btn-sm"
                                    to="/admin/service/localdata"
                                    size="sm"
                                    onClick={() => this.addLocalData()}

                                > Import</Link>
                            </Row>
                        </CardHeader>
                    </Card>
                </Container >
            </div >

        )
    }
}


function mapStateToProps(state) {
    return { authenticated: state.auth.authenticated, user_credentials: state.auth.user_credentials };
}

export default compose(
    connect(mapStateToProps, actions)
)(withRouter(DataSetImporterAdd));

