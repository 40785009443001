
import axios  from 'axios'

const asyncValidate = (values, dispatch, props, field) => {
    let validationPromises = [
        {
            field: "username",
            request: fetch(process.env.REACT_APP_URL_Orchestrator + '/user/validate-username', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 'username': values.username })
            }).then(resp=>resp.json()),
            error: "Username exist"
        },
        {
            field: "email",
            request:
                fetch(process.env.REACT_APP_URL_Orchestrator + '/user/validate-email', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ 'email': values.email })
                }).then(resp=>resp.json())
            ,
            error: "Should be a valid email!"
        }
    ]

    let promisesResolved = validationPromises.map(promise => promise.request.catch(error => ({ error })));

    return axios.all(promisesResolved).then(
        (args) => {
            let errors = {};
            args.forEach((r, idx) => {
                //console.log(r)
                if (!r.status && r.status!=='Valid') {
                    errors[validationPromises[idx].field] = validationPromises[idx].error
                }
            })
            if (Object.keys(errors).length > 0) {
                throw errors
            }
        }
    )
}

export default asyncValidate;
