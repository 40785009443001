
//import Login from "components/Footers/AuthFooter";
import React, { Component } from 'react';
//import { reduxForm, Field } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import * as actions from './../../../actions'
//import { Link, NavLink } from "react-router-dom";

//import { render } from 'react-dom';

import { getData } from "./utils"
import {
	CardBody,Spinner
} from "reactstrap";


import ReactApexChart from 'react-apexcharts'

class BotsMarketGraphic extends Component {
	constructor(props) {
		////console.log("Constructor de Bots")
		super(props);
		this.state = {
			options: {
				chart: {
					height: 100,
					type: 'line',
					id: 'areachart-2'
				},
				annotations: {
					points: []
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					curve: 'straight',
					width: 2
				},
				grid: {
					padding: {
						right: 30,
						left: 20
					}
				},
				title: {
					text: 'Bot tradings',
					align: 'left'
				},
				labels: null,
				xaxis: {
					type: 'datetime',
				}
			},
			series: [

			]
		}
	}
	componentDidMount() {

		var exchange = this.props.exchange;
		var currency = this.props.currency;
		var asset = this.props.asset;
		var start = this.props.start;
		var stop = this.props.stop;
		exchange = exchange.toLowerCase();

		if (stop === false) {

			var d = new Date();
			var luna = (d.getMonth() + 1) <= 9 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1);
			var zi = d.getDate() <= 9 ? '0' + d.getDate() : d.getDate();
			var minute = d.getMinutes() <= 9 ? '0' + d.getMinutes() : d.getMinutes();

			var datestring = d.getFullYear() + "-" + luna + "-" + zi + "T" + d.getHours() + ":" + minute + ":00.000Z";


			getData(this.props.user_credentials.user_id, this.props.user_credentials.bot_id, this.props.authenticated, exchange, currency, asset, start, datestring).then(data => {
				var objdata = JSON.parse(data);
				console.log(objdata)
				var dataArray = [];
				var priceArray = [];
				var puncteGrafic = [];
				var puncteTrades = this.props.tradeCompleted;
				console.log(puncteTrades)
				if (puncteTrades != "empty") {
					puncteTrades.map(element => {
						var data = new Date(element.date);
						var timestamp = data.getTime();
						var punct =
						{
							x: timestamp,
							y: element.price,
							marker: {
								size: 3,
								fillColor: '#fff',
								strokeColor: element.action == 'sell' ? "red" : "green",
								radius: 2,
								cssClass: 'apexcharts-custom-class'
							},
							label: {
								borderColor: '#FF4560',
								offsetY: 0,
								style: {
									color: '#fff',
									background: '#FF4560',
								}
							}
						}
						puncteGrafic.push(punct);
					});
				}
				objdata.map(element => {
					dataArray.push(element.start * 1000)
					priceArray.push(element.close)
				})
				this.setState({
					options: {
						...this.state.options,
						labels: dataArray,
						annotations: { points: puncteGrafic }
					}
				})

				this.setState({
					series: [
						{
							data: priceArray
						}
					]

				})
			})
				.catch(error => {
					//console.log(error)
				})
		}
	}
	render() {
		if (this.state == null) {
			return <div>Loading...</div>
		}
		return (
			<div >
				{console.log(this.state.series)}
				<CardBody>
					{this.state.options.labels !== null ?

						<ReactApexChart options={this.state.options} series={this.state.series} width="100%" height="500" />
						: 
						<center>
						<Spinner color="success" />
					</center>
						}

				</CardBody>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return { authenticated: state.auth.authenticated, user_credentials: state.auth.user_credentials };
}

export default compose(
	connect(mapStateToProps, actions)
)(withRouter(BotsMarketGraphic));

