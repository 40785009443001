//import Login from "components/Footers/AuthFooter";
import React, { Component } from 'react';
//import { reduxForm, Field } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
//import { Link, NavLink } from "react-router-dom";
import axios from 'axios';
//import Header from '../Headers/Header';
import Switch from '@mui/material/Switch';
import BacktestSimulationsConf from '../BacktestSimulationsConf/BacktestSimulationsConf'
// reactstrap components
import {
    //  Button,
    Card,
    CardHeader,
    CardBody,
    //  FormGroup,
    //  Form,
    Input,
    //  InputGroupAddon,
    //  InputGroupText,
    //  InputGroup,
    Row,
    Col,
    //  Container,
    Label,
    //  Spinner,
} from "reactstrap";

//import BotsDatasets from "./BotsDataSets/BotsDataSets";
/*
const StrategyOptions = [
    { value: 'MACD', label: 'MACD' },
    { value: 'Supertrend_Gab0', label: 'Supertrend_Gab0' },
    { value: 'TA4Prof2', label: 'TA4Prof2' },
    { value: 'TEMA', label: 'TEMA' },
  ];
*/
const TimeUnitsOptions = [
    { value: 'minutes', label: 'minutes' },
    { value: 'hours', label: 'hours' },
    { value: 'days', label: 'days' },
];

const label = { inputProps: { 'aria-label': 'Switch demo' } };

class BacktestStrategy extends Component {

    constructor() {
        super();
        ////console.log("Component botslist")
        this.state = {
            isLoaded: true,
            isFetching: true,
            strategies: [],
            strategy: '',
            parameters: '',
            paperTrader: {
                enabled: true,
                feeMaker:0.25,
                feeTaker :0.25,
                feeUsing:"maker",
                reportRoundtrips:true,
                simulationBalance :{ asset: 1, currency: 100 },
                    slippage: 0.05
            },
            paramsJson: {},
            timeunits: 'minutes',
            showMacd: true,
            showPSAR: true,
            showIchimoku: true,
            showTrend: true,
            showStoploss: true,
            showTakeProfit: false,
            firstMove: true,
            macd: {
                short: 13,
                long: 25,
                signal: 6,
                thresholdDOWN: 0,
                thresholdUP: 0
            },
            psar: {
                optInAcceleration: 0.25,
                optInMaximum: 0.02,
                optInStart: 0.02
            },
            ichimoku: {
                conversionPeriod: 11,
                basePeriod: 30,
                spanPeriod: 48,
                displacement: 30
            },
            marketTrend: {
                EMAlong: 130,
                EMAshort: 20,
                persistence: 3
            },
            limit: {
                loss: 10,
            },
            takeprofit: {
                gain: 10
            },
            warmup: 10,
            candlesize: 1,
            isFetchingParams: true,
            isLoadedParams: true,
            tradingAdvisor: {
                candleSize: 60,
                enabled: true,
                historySize: 10,
                method: ''
            }
        }
    }


    //listStrategy = StrategyOptions.map((d) => 
    //    <option>{d.value}</option>
    //);

    listTimeUnits = TimeUnitsOptions.map((d) =>
        <option>{d.value}</option>
    );

    isNumeric(str) {
        if (typeof str != "string") return false // we only process strings!  
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }


    onChangeParams_ = (e, key) => {
        this.setState({ isFetching: true, isLoaded: false })

        let JSON = this.state.paramsJson
        JSON[key] = parseInt(e.target.value)
        ////console.log("Modificare: ", JSON[key])
        this.props.updateConf(this.state.strategy, JSON)
        this.setState({ isLoaded: true, isFetching: false })
    }

    onChangeCandleSize = (e) => {
        this.setState({ isLoadedParams: false, isFetchingParams: true })
        this.setState({ candlesize: e.target.target })
        let candlesize = this.state.tradingAdvisor
        let timeunits = 1
        if (this.state.timeunits === 'minutes') {
            timeunits = 1
        } else if (this.state.timeunits === 'hours') {
            timeunits = 60
        } else if (this.state.timeunits === 'days') {
            timeunits = 1440
        }
        candlesize["candleSize"] = e.target.value * timeunits
        this.setState({ tradingAdvisor: candlesize })
        ////console.log("New candle size: " + e.target.value)
        //update
        if (this.state.strategy !== '') {
            this.props.updateConfStrategyParam(candlesize)
        }
        this.setState({ isLoadedParams: true, isFetchingParams: false })
    }

    onChangeTimeUnits = (e) => {
        this.setState({ isLoadedParams: false, isFetchingParams: true })
        this.setState({ timeunits: e.target.value })
        let timeunits = this.state.tradingAdvisor
        let timeunit = 1
        if (e.target.value === 'minutes') {
            timeunit = 1
        } else if (e.target.value === 'hours') {
            timeunit = 60
        } else if (e.target.value === 'days') {
            timeunits = 1440
        }

        timeunits['candleSize'] = this.state.candlesize * timeunit
        this.setState({ tradingAdvisor: timeunits })
        ////console.log("New Time Units: " + e.target.value)

        //update
        if (this.state.strategy !== '') {
            this.props.updateConfStrategyParam(timeunits)
        }
        this.setState({ isLoadedParams: true, isFetchingParams: false })
    }
    confBotAddUpdateSimulation = (SimulationParams) => {
        //console.log("Simulation parameters update from parent")
        let confSimulation = this.state.confBotAdd
        confSimulation["paperTrader"] = SimulationParams
        this.setState({ confBotAdd: confSimulation })
    }
    confBacktestAddUpdateSimulation = (SimulationParams) => {
        //console.log("Simulation parameters for backtest")
        this.setState({ paperTrader: SimulationParams })
        //console.log(this.state.backtest_bot)
    }



    onChangeWarmUp = (e) => {
        this.setState({ isLoadedParams: false, isFetchingParams: true })
        this.setState({ warmup: e.target.value })
        ////console.log("New WarmUp period: " + e.target.value)
        let warmup = this.state.tradingAdvisor
        warmup['warmup'] = e.target.value
        this.setState({ tradingAdvisor: warmup })
        if (this.state.strategy !== '') {
            this.props.updateConfStrategyParam(warmup)
        }
        this.setState({ isLoadedParams: true, isFetchingParams: false })
    }

    render() {

        const {
            isLoaded,
            strategies,
            //        isFetching,
            //        strategy,
            //        parameters,
            timeunits,
            warmup,
            candlesize,
            paramsJson,
            isLoadedParams,
            //        isFetchingParams,
        } = this.state;



        return (
            <div>
                {isLoaded ?


                    <Row>
                        <Col className="mt-5">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Strategy</h3>
                                        </div>
                                        <div>
                                            <i className="fas fa-chess-king text-success fa-2x"></i>
                                        </div>
                                        {/*<div className="col">
                                    <i className="fas fa-btc text-success fa-2x"></i>
                                </div>*/}
                                    </Row>
                                </CardHeader>
                                <CardBody>

                                    <Row>
                                        <Col>

                                            <Label for="exampleSelect">Strategy name</Label>
                                            <br></br>
                                            <b style={{ marginLeft: "10px" }}>TA4Prof2</b>
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col>
                                            <label for="inputsm">Candle size</label>
                                            <Input
                                                className="input-group input-group-sm"
                                                id="candlesize"
                                                type="text"
                                                bsSize="sm"
                                                defaultValue={candlesize}
                                                onBlur={(e) => this.onChangeCandleSize(e)}
                                            >
                                            </Input>
                                        </Col>
                                        <Col>
                                            <Label for="exampleSelect">(Time Unit)</Label>
                                            <Input
                                                type="select"
                                                name="select"
                                                id="timeunit"
                                                className="input-group select-group-sm"
                                                bsSize="sm"
                                                defaultValue={timeunits}
                                                onChange={(e) => this.onChangeTimeUnits(e)}
                                            >
                                                {this.listTimeUnits}
                                            </Input>
                                        </Col>

                                    </Row>
                                    <Row className="mt-2">
                                        <Col>
                                            <Label for="exampleSelect">Warmup period (in 1 hour candles):</Label>
                                            <Input
                                                className="input-group input-group-sm"
                                                id="inputsm"
                                                type="text"
                                                bsSize="sm"
                                                defaultValue={warmup}
                                                onBlur={(e) => this.onChangeWarmUp(e)}
                                            >
                                            </Input>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <Card className="shadow" style={{ marginTop: "10px" }}>
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Simulation Configuration</h3>
                                        </div>
                                        <div>
                                            <i className="fas fa-screwdriver text-success fa-2x"></i>
                                        </div>
                                        {/*<div className="col">
                                    <i className="fas fa-btc text-success fa-2x"></i>
                                </div>*/}
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <BacktestSimulationsConf
                                        updateConf={this.props.updateConfSimulation}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col className="mt-5">
                            {console.log(this.state)}
                            <Col >
                                <Card className="shadow">
                                    <CardHeader className="border-0">
                                        <Row className="align-items-center">
                                            <div className="col">
                                                <h3 className="mb-0">Parameters</h3>
                                            </div>
                                            <div>
                                                <i className="fas fa-wrench text-success fa-2x"></i>
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <div>
                                            <p>Bot Settings</p>

                                            <b style={{ marginLeft: "10px" }}>First Move:</b>
                                            <span style={{ float: "right" }}>Sell<Switch {...label} defaultChecked onClick={(e) => { this.setState({ firstMove: !this.state.firstMove }); this.props.changeTa4prof2(this.state.firstMove == false ? 'buy' : 'sell', 'BOT_SETTINGS.First_Move') }} />Buy</span>
                                            <p>Indicators</p>

                                            <b style={{ marginLeft: "10px" }}>MACD:</b>
                                            <span style={{ float: "right" }}>False<Switch {...label} defaultChecked onClick={(e) => { this.setState({ showMacd: !this.state.showMacd }); this.props.changeTa4prof2(!this.state.showMacd, 'Indicators.MACD') }} />True</span>
                                            <p></p>
                                            {this.state.showMacd ?
                                                <div>
                                                    <Row style={{ marginBottom: "5px" }}>
                                                        <Col>
                                                            <b style={{ marginLeft: "25px" }}> short:</b>
                                                        </Col>

                                                        <Col>
                                                            <Input
                                                                invalid={isNaN(this.state.macd.short) ? true : false}
                                                                id="MACD.short"
                                                                type="text"
                                                                onChange={(e) => { this.setState({ macd: { ...this.state.macd, short: parseFloat(e.target.value) } }) }}
                                                                onBlur={(e) => this.props.changeTa4prof2(parseFloat(e.target.value), e.currentTarget.id)}
                                                                defaultValue={this.state.macd.short} />
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginBottom: "5px" }}>
                                                        <Col>
                                                            <b style={{ marginLeft: "25px" }}> long:</b>
                                                        </Col>
                                                        <Col>

                                                            <Input id="MACD.long" type="text" invalid={isNaN(this.state.macd.long) ? true : false} defaultValue={this.state.macd.long} onBlur={(e) => this.props.changeTa4prof2(parseFloat(e.target.value), e.currentTarget.id)} onChange={(e) => { this.setState({ macd: { ...this.state.macd, long: parseFloat(e.target.value) } }) }} />
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginBottom: "5px" }}>
                                                        <Col>
                                                            <b style={{ marginLeft: "25px" }}> signal:</b>
                                                        </Col>
                                                        <Col>
                                                            <Input type="text" id="MACD.signal" invalid={isNaN(this.state.macd.signal) ? true : false} defaultValue={this.state.macd.signal} onBlur={(e) => this.props.changeTa4prof2(parseFloat(e.target.value), e.currentTarget.id)} onChange={(e) => { this.setState({ macd: { ...this.state.macd, signal: parseFloat(e.target.value) } }) }} />
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginBottom: "5px" }}>
                                                        <Col>
                                                            <b style={{ marginLeft: "25px" }}> thresholdDOWN:</b>
                                                        </Col>
                                                        <Col>
                                                            <Input type="text" id="MACD.thresholdDOWN" invalid={isNaN(this.state.macd.thresholdDOWN) ? true : false} defaultValue={this.state.macd.thresholdDOWN} onBlur={(e) => this.props.changeTa4prof2(parseFloat(e.target.value), e.currentTarget.id)} onChange={(e) => { this.setState({ macd: { ...this.state.macd, thresholdDOWN: parseFloat(e.target.value) } }) }} />
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginBottom: "5px" }}>
                                                        <Col>
                                                            <b style={{ marginLeft: "25px" }}> thresholdUP:</b>
                                                        </Col>
                                                        <Col>
                                                            <Input type="text" id="MACD.thresholdUP" invalid={isNaN(this.state.macd.thresholdUP) ? true : false} defaultValue={this.state.macd.thresholdUP} onBlur={(e) => this.props.changeTa4prof2(parseFloat(e.target.value), e.currentTarget.id)} onChange={(e) => { this.setState({ macd: { ...this.state.macd, thresholdUP: parseFloat(e.target.value) } }) }} />
                                                        </Col>
                                                    </Row>
                                                </div>
                                                : null}

                                            <b style={{ marginLeft: "10px" }}>PSAR:</b>
                                            <span style={{ float: "right" }}>False<Switch {...label} defaultChecked onClick={(e) => { this.setState({ showPSAR: !this.state.showPSAR }); this.props.changeTa4prof2(!this.state.showPSAR, 'Indicators.PSAR') }} />True</span>
                                            <p></p>
                                            {this.state.showPSAR ?
                                                <div>
                                                    <Row style={{ marginBottom: "5px" }}>
                                                        <Col>
                                                            <b style={{ marginLeft: "25px" }}> optInAcceleration:</b>
                                                        </Col>
                                                        <Col>
                                                            <Input type="text" id="PSAR.optInAcceleration" invalid={isNaN(this.state.psar.optInAcceleration) ? true : false} defaultValue={this.state.psar.optInAcceleration} onBlur={(e) => this.props.changeTa4prof2(parseFloat(e.target.value), e.currentTarget.id)} onChange={(e) => { this.setState({ psar: { ...this.state.psar, optInAcceleration: parseFloat(e.target.value) } }) }} />
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginBottom: "5px" }}>
                                                        <Col>
                                                            <b style={{ marginLeft: "25px" }}> optInMaximum:</b>
                                                        </Col>
                                                        <Col>
                                                            <Input type="text" id="PSAR.optInMaximum" invalid={isNaN(this.state.psar.optInMaximum) ? true : false} defaultValue={this.state.psar.optInMaximum} onBlur={(e) => this.props.changeTa4prof2(parseFloat(e.target.value), e.currentTarget.id)} onChange={(e) => { this.setState({ psar: { ...this.state.psar, optInMaximum: parseFloat(e.target.value) } }) }} />
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginBottom: "5px" }}>
                                                        <Col>
                                                            <b style={{ marginLeft: "25px" }}> optInStart:</b>
                                                        </Col>
                                                        <Col>
                                                            <Input type="text" id="PSAR.optInStart" invalid={isNaN(this.state.psar.optInStart) ? true : false} defaultValue={this.state.psar.optInStart} onBlur={(e) => this.props.changeTa4prof2(parseFloat(e.target.value), e.currentTarget.id)} onChange={(e) => { this.setState({ psar: { ...this.state.psar, optInStart: parseFloat(e.target.value) } }) }} />
                                                        </Col>
                                                    </Row>
                                                </div>
                                                : null}

                                            <b style={{ marginLeft: "10px" }}>ICHIMOKU:</b>
                                            <span style={{ float: "right" }}>False<Switch {...label} defaultChecked onClick={(e) => { this.setState({ showIchimoku: !this.state.showIchimoku }); this.props.changeTa4prof2(!this.state.showIchimoku, 'Indicators.ICHIMOKU') }} />True</span>
                                            <br></br>
                                            <p></p>
                                            {this.state.showIchimoku ?
                                                <div>
                                                    <Row style={{ marginBottom: "5px" }}>
                                                        <Col>
                                                            <b style={{ marginLeft: "25px" }}> conversionPeriod:</b>
                                                        </Col>
                                                        <Col>
                                                            <Input type="text" id="ICHIMOKU.conversionPeriod" invalid={isNaN(this.state.ichimoku.conversionPeriod) ? true : false} defaultValue={this.state.ichimoku.conversionPeriod} onBlur={(e) => this.props.changeTa4prof2(parseFloat(e.target.value), e.currentTarget.id)} onChange={(e) => { this.setState({ ichimoku: { ...this.state.ichimoku, conversionPeriod: parseFloat(e.target.value) } }) }} />
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginBottom: "5px" }}>
                                                        <Col>
                                                            <b style={{ marginLeft: "25px" }}> basePeriod:</b>
                                                        </Col>
                                                        <Col>
                                                            <Input type="text" id="ICHIMOKU.basePeriod" invalid={isNaN(this.state.ichimoku.basePeriod) ? true : false} defaultValue={this.state.ichimoku.basePeriod} onBlur={(e) => this.props.changeTa4prof2(parseFloat(e.target.value), e.currentTarget.id)} onChange={(e) => { this.setState({ ichimoku: { ...this.state.ichimoku, basePeriod: parseFloat(e.target.value) } }) }} />
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginBottom: "5px" }}>
                                                        <Col>
                                                            <b style={{ marginLeft: "25px" }}> spanPeriod:</b>
                                                        </Col>
                                                        <Col>
                                                            <Input type="text" id="ICHIMOKU.spanPeriod" invalid={isNaN(this.state.ichimoku.spanPeriod) ? true : false} defaultValue={this.state.ichimoku.spanPeriod} onBlur={(e) => this.props.changeTa4prof2(parseFloat(e.target.value), e.currentTarget.id)} onChange={(e) => { this.setState({ ichimoku: { ...this.state.ichimoku, spanPeriod: parseFloat(e.target.value) } }) }} />
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginBottom: "5px" }}>
                                                        <Col>
                                                            <b style={{ marginLeft: "25px" }}> displacement:</b>
                                                        </Col>
                                                        <Col>
                                                            <Input type="text" id="ICHIMOKU.displacement" invalid={isNaN(this.state.ichimoku.displacement) ? true : false} defaultValue={this.state.ichimoku.displacement} onBlur={(e) => this.props.changeTa4prof2(parseFloat(e.target.value), e.currentTarget.id)} onChange={(e) => { this.setState({ ichimoku: { ...this.state.ichimoku, displacement: parseFloat(e.target.value) } }) }} />
                                                        </Col>
                                                    </Row>
                                                </div>
                                                : null}

                                            <p></p>
                                            <b style={{ marginLeft: "10px" }}>TREND:</b>
                                            <span style={{ float: "right" }}>False<Switch {...label} defaultChecked onClick={(e) => { this.setState({ showTrend: !this.state.showTrend }); this.props.changeTa4prof2(!this.state.showTrend, 'Indicators.TREND') }} />True</span>
                                            <br></br>
                                            <p></p>
                                            {this.state.showTrend ?
                                                <div>
                                                    <Row style={{ marginBottom: "5px" }}>
                                                        <Col>
                                                            <b style={{ marginLeft: "25px" }}> EMAlong:</b>
                                                        </Col>
                                                        <Col>
                                                            <Input type="text" id="MARKET_TREND.EMAlong" invalid={isNaN(this.state.marketTrend.EMAlong) ? true : false} defaultValue={this.state.marketTrend.EMAlong} onBlur={(e) => this.props.changeTa4prof2(parseFloat(e.target.value), e.currentTarget.id)} onChange={(e) => { this.setState({ marketTrend: { ...this.state.marketTrend, EMAlong: parseFloat(e.target.value) } }) }} />
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginBottom: "5px" }}>
                                                        <Col>
                                                            <b style={{ marginLeft: "25px" }}> EMAshort:</b>
                                                        </Col>
                                                        <Col>
                                                            <Input type="text" id="MARKET_TREND.EMAshort" invalid={isNaN(this.state.marketTrend.EMAshort) ? true : false} defaultValue={this.state.marketTrend.EMAshort} onBlur={(e) => this.props.changeTa4prof2(parseFloat(e.target.value), e.currentTarget.id)} onChange={(e) => { this.setState({ marketTrend: { ...this.state.marketTrend, EMAshort: parseFloat(e.target.value) } }) }} />
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginBottom: "5px" }}>
                                                        <Col>
                                                            <b style={{ marginLeft: "25px" }}> persistence:</b>
                                                        </Col>
                                                        <Col>
                                                            <Input type="text" id="MARKET_TREND.persistence" invalid={isNaN(this.state.marketTrend.persistence) ? true : false} defaultValue={this.state.marketTrend.persistence} onBlur={(e) => this.props.changeTa4prof2(parseFloat(e.target.value), e.currentTarget.id)} onChange={(e) => { this.setState({ marketTrend: { ...this.state.marketTrend, persistence: parseFloat(e.target.value) } }) }} />
                                                        </Col>
                                                    </Row>
                                                </div>
                                                : null}
                                            <p></p>
                                            <b style={{ marginLeft: "10px" }}>STOPLOSS:</b>
                                            <span style={{ float: "right" }}>False<Switch {...label} defaultChecked onClick={(e) => { this.setState({ showStoploss: !this.state.showStoploss }); this.props.changeTa4prof2(!this.state.showStoploss, 'Indicators.STOPLOSS') }} />True</span>
                                            <br></br>
                                            <p></p>
                                            {this.state.showStoploss ?
                                                <div>
                                                    <Row style={{ marginBottom: "5px" }}>
                                                        <Col>
                                                            <b style={{ marginLeft: "25px" }}> loss:</b>
                                                        </Col>
                                                        <Col>
                                                            <Input type="text" id="LIMIT.loss" invalid={isNaN(this.state.limit.loss) ? true : false} defaultValue={this.state.limit.loss} onBlur={(e) => this.props.changeTa4prof2(parseFloat(e.target.value), e.currentTarget.id)} onChange={(e) => { this.setState({ limit: { ...this.state.limit, loss: parseFloat(e.target.value) } }) }} />
                                                        </Col>
                                                    </Row>


                                                </div>
                                                : null}
                                            <p></p>
                                            <b style={{ marginLeft: "10px" }}>TAKEPROFIT:</b>
                                            <span style={{ float: "right" }}>False<Switch {...label} onClick={(e) => { this.setState({ showTakeProfit: !this.state.showTakeProfit }); this.props.changeTa4prof2(!this.state.showTakeProfit, 'Indicators.TAKEPROFIT') }} />True</span>
                                            <p></p>
                                            {this.state.showTakeProfit ?
                                                <div>
                                                    <Row style={{ marginBottom: "5px" }}>
                                                        <Col>
                                                            <b style={{ marginLeft: "25px" }}> gain:</b>
                                                        </Col>
                                                        <Col>
                                                            <Input type="text" id="LIMIT.gain" invalid={isNaN(this.state.takeprofit.gain) ? true : false} defaultValue={this.state.takeprofit.gain} onBlur={(e) => this.props.changeTa4prof2(parseFloat(e.target.value), e.currentTarget.id)} onChange={(e) => { this.setState({ takeprofit: { ...this.state.takeprofit, gain: parseFloat(e.target.value) } }) }} />
                                                        </Col>
                                                    </Row>
                                                </div>
                                                : null}
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Col>
                    </Row>

                    :
                    null
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { authenticated: state.auth.authenticated, user_credentials: state.auth.user_credentials };
}

export default compose(
    connect(mapStateToProps, actions)
)(BacktestStrategy);



