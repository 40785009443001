//import Login from "components/Footers/AuthFooter";
import React, { Component } from 'react';
//import { reduxForm, Field } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';

//import { Link, NavLink } from "react-router-dom";
import * as actions from './../../../actions';
//import { render } from 'react-dom';
import {
	CardBody
} from "reactstrap";


import ReactApexChart from 'react-apexcharts'



class BacktestMarketGraphic extends Component {
	constructor(props) {
		//
		////console.log("Constructor de Bots")
		super(props);
		this.state = {
			options: {
				chart: {
					height: 200,
					type: 'line',
					id: 'areachart-2'
				},
				annotations: {
					points: []
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					curve: 'straight',
					width: 2
				},
				grid: {
					padding: {
						right: 30,
						left: 20
					}
				},
				title: {
					text: 'Bot tradings',
					align: 'left'
				},
				labels: null,
				xaxis: {
					type: 'datetime',
				}
			},
			series: [

			]
		}
	}
	componentDidMount() {

		var puncteGrafic = [];
		var puncteTrades = this.props.prices;
		puncteTrades.map(element => {
			var punct =
			{
				x: element.date*1000,
				y:element.price,
				marker: {
					size: 3,
					fillColor: '#fff',
					strokeColor: element.action=='sell' ? "red" : "green",
					radius: 2,
					cssClass: 'apexcharts-custom-class'
				},
				label: {
					borderColor: '#FF4560',
					offsetY: 0,
					style: {
						color: '#fff',
						background: '#FF4560',
					}
				}
			}
			puncteGrafic.push(punct);
		})

		var objdata = this.props.stratCandles
		var dataArray = [];
		var priceArray = [];
		objdata.map(element => {

			dataArray.push(element.start * 1000)
			priceArray.push(element.open)
		})
		this.setState({
			options: {
				...this.state.options,
				labels: dataArray,
				annotations:{points:puncteGrafic}

			}
		})

		this.setState({
			series: [
				{
					data: priceArray
				}
			]
		})
//
	}
	render() {
		if (this.state == null) {
			return <div>Loading...</div>
		}
		return (
			<div>
	
				<CardBody>
					{this.state.options.labels !== null ?
						<ReactApexChart width="100%" height="400" options={this.state.options} series={this.state.series} />
						: null}

				</CardBody>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return { authenticated: state.auth.authenticated, user_credentials: state.auth.user_credentials };
}

export default compose(
	connect(mapStateToProps, actions)
)(BacktestMarketGraphic);