// ver: 0.92 19.03.2021
// author: Cristian Vasiliu (Max4Prof)

import React, { Component }  from 'react';
import moment from 'moment';
import {
  Row,
  Col,
  Container,
} from "reactstrap";

class BotGetRuntime extends Component {

    data_spanning = () => {   
        let data_spanning = ''
        const diff = moment(this.props.receive_data_until).diff(moment(this.props.watching_since));
        const diffDuration = moment.duration(diff);
        if (diffDuration.minutes()) { data_spanning = diffDuration.minutes() + " minutes"}
        if (diffDuration.hours()) { data_spanning = diffDuration.hours() + " hours " + data_spanning}
        if (diffDuration.days()) { data_spanning = diffDuration.days() + " days " + data_spanning}   
        if (diffDuration.months()) { data_spanning = diffDuration.months() + " months " + data_spanning}
        if (diffDuration.years()) { data_spanning = diffDuration.years() + " years " + data_spanning}
        return data_spanning
    }

    render () {
        return(
            <div>
            <Container>
            <Row>
                <Col>
                    <h4>Watching since:</h4>
                </Col>
                <Col>
                    <div>{moment(this.props.watching_since).format('YYYY-MM-DD HH:mm')}</div>
                </Col>
            </Row>

            <Row className="mt-2">
                <Col>
                    <h4>Received data until:</h4>
                </Col>
                <Col>
                    <div>{moment(this.props.receive_data_until).format('YYYY-MM-DD HH:mm')}</div>
                </Col>
            </Row>
            
            <Row className="mt-2">
                <Col>
                    <h4>Data spanning:</h4>
                </Col>
                <Col>
                    <div>{this.data_spanning()}</div>
                </Col>
            </Row>
            
            {(this.props.bot_type !== "market watcher") ? <Row className="mt-2">
                <Col>
                    <h4>Amount of trades:</h4>
                </Col>
                <Col>
                    <div>{this.props.amount_of_trades}</div>
                </Col>
            </Row>: null }
            
            {(this.props.bot_type !== "market watcher") ? <Row className="mt-2">
                <Col>
                    <h4>Candle size:</h4>
                </Col>
                <Col>
                    <div>{this.props.candle_size}</div>
                </Col>
            </Row>: null }
            
            {(this.props.bot_type !== "market watcher") ? <Row className="mt-2">
                <Col>
                    <h4>History size:</h4>
                </Col>
                <Col>
                    <div>{this.props.history_size}</div>
                </Col>
            </Row>: null }

            </Container>

            </div>
        )
    }
}

export default BotGetRuntime;


