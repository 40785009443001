//import Login from "components/Footers/AuthFooter";
import React, { Component }  from 'react';
//import { reduxForm, Field } from 'redux-form';
//import { compose } from 'redux';
//import { connect } from 'react-redux';
//import * as actions from '../../../../actions'
//import { Link, NavLink } from "react-router-dom";
//import Header from '../Headers/Header';

// reactstrap components
import {
  Row,
  Col,
  Container,
} from "reactstrap";

//import Select from 'react-select';

class BotGetProfit extends Component {

    checkIfNotProps = () => {
        //console.log("checkIfProps")            
        
        if (typeof this.props.startBalance !== 'undefined') {
            return false
        } else {
            return true
        }
         
    }

    render () {
        
        if ( this.checkIfNotProps() ) {
        return (
            <div>
            <Container>
            <Row>
                <Col>
                <h4>Waiting for at least one trade...</h4>
                </Col>
            </Row>
            </Container>
            </div>
        )
        } else {

        return (
            <div>
            <Container>
            <Row>
                <Col>
                    <h4>Start balance:</h4>
                </Col>
                <Col>
                    <div>{parseFloat(this.props.startBalance).toFixed(2)} {this.props.currency}</div>
                </Col>
            
            </Row>
            <Row className="mt-2">
                <Col>
                    <h4>Current balance:</h4>
                </Col>
                <Col>
                    <div>{parseFloat(this.props.balance).toFixed(2)} {this.props.currency}</div>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col>
                    <h4>Market:</h4>
                </Col>
                <Col>
                    <div>{parseFloat(this.props.endPrice - this.props.startPrice).toFixed(2)} {this.props.currency} ({parseFloat(this.props.market).toFixed(2)}%)</div>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col>
                    <h4>Profit:</h4>
                </Col>
                <Col>
                    <div>{parseFloat(this.props.profit).toFixed(2)} {this.props.currency} ({parseFloat(this.props.relativeProfit).toFixed(2)}%)</div>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col>
                    <h4>Alpha:</h4>
                </Col>
                <Col>
                    <div>{parseFloat(this.props.alpha).toFixed(2)} {this.props.currency}</div>
                </Col>
            </Row>
            
            </Container>

            </div>
        )
        }
    }
}

export default BotGetProfit;


