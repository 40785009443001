//import Login from "components/Footers/AuthFooter";
import React, { Component } from 'react';
//import { reduxForm, Field } from 'redux-form';
//import { compose } from 'redux';
//import { connect } from 'react-redux';
//import { Link, NavLink } from "react-router-dom";
//import Header from '../Headers/Header';

// reactstrap components
import {
//  Button,
//  Card,
//  CardHeader,
//  CardBody,
//  FormGroup,
//  Form,
  Input,
//  InputGroupAddon,
//  InputGroupText,
//  InputGroup,
  Row,
  Col,
  Container,
  Label,
} from "reactstrap";

//import BotsDatasets from "./BotsDataSets/BotsDataSets";



class BotsSimulationsConf extends Component {

    constructor() {
        super();
        this.state = {
          simulationParams: {
              enabled: true,
              feeMaker: 0.25,
              feeTaker: 0.25,
              feeUsing: "maker",
              reportRoundtrips: true,
              simulationBalance: {
                  asset: 1,
                  currency: 100
              },
              slippage: 0.05
          },
          isLoaded: false,
          isFetching: false,
          updateData: false
        }
      }

    componentDidMount () {
        this.setState({isLoaded: true})
    }

    onChangeSimulationParamsfeeMaker = (event) => {
        //console.log("onChangeSimulationParamsfeeMaker")
        this.setState({isLoaded: false, isFetching: true})
        let SimulationsParams = this.state.simulationParams
        
        SimulationsParams["feeMaker"] = parseFloat(event.target.value)
        this.props.updateConfSimulation(SimulationsParams)
        this.setState({simulationParams: SimulationsParams})
        this.setState({isLoaded: true, isFetching: false})
    }

    onChangeSimulationParamsfeeTaker = (event) => {
        //console.log("onChangeSimulationParamsfeeTaker")
        this.setState({isLoaded: false, isFetching: true})
        let SimulationsParams = this.state.simulationParams
        SimulationsParams["feeTaker"] = parseFloat(event.target.value)
        this.props.updateConfSimulation(SimulationsParams)
        this.setState({simulationParams: SimulationsParams})
        this.setState({isLoaded: true, isFetching: false})
    }

    onChangeSimulationParamsfeeUsing = (event) => {
        //console.log("onChangeSimulationParamsfeeUsing")
        this.setState({isLoaded: false, isFetching: true})
        let SimulationsParams = this.state.simulationParams
        SimulationsParams["feeUsing"] = event.target.value        
        this.props.updateConfSimulation(SimulationsParams)
        this.setState({simulationParams: SimulationsParams})
        this.setState({isLoaded: true, isFetching: false})
    }

    onChangeSimulationParamsSlippage = (event) => {
        //console.log("onChangeSimulationParamsSlippage")
        this.setState({isLoaded: false, isFetching: true})
        let SimulationsParams = this.state.simulationParams
        SimulationsParams["slippage"] = parseFloat(event.target.value)       
        this.props.updateConfSimulation(SimulationsParams)
        this.setState({simulationParams: SimulationsParams})
        this.setState({isLoaded: true, isFetching: false})
    }

    onChangeSimulationParamsAsset = (event) => {
        //console.log("onChangeSimulationParamsAsset")
        this.setState({isLoaded: false, isFetching: true})
        let SimulationsParams = this.state.simulationParams
        SimulationsParams["simulationBalance"]["asset"] = parseFloat(event.target.value) 
        this.props.updateConfSimulation(SimulationsParams)
        this.setState({simulationParams: SimulationsParams})
        this.setState({isLoaded: true, isFetching: false})
    }

    onChangeSimulationParamsCurrency = (event) => {
        //console.log("onChangeSimulationParamsCurrency")
        this.setState({isLoaded: false, isFetching: true})
        let SimulationsParams = this.state.simulationParams
        SimulationsParams["simulationBalance"]["currency"] = parseFloat(event.target.value)     
        this.props.updateConfSimulation(SimulationsParams)
        this.setState({simulationParams: SimulationsParams})
        this.setState({isLoaded: true, isFetching: false})
    }


    render () {
        return(
            <div>
            {this.state.isLoaded && 
            <Container>
                
                <Row>
                    <Col><Label><h4>Settings</h4></Label></Col>
                </Row>

                <Row className="mt-2">
                    <Col>Market Fee:</Col>
                    <Col>
                        <Input 
                            className="input-group input-group-sm" 
                            id="marketfee" 
                            type="text" 
                            defaultValue={this.state.simulationParams.feeMaker}
                            size="sm"
                            onChange={this.onChangeSimulationParamsfeeMaker}
                            > 
                        </Input>
                    </Col>
                </Row>

                <Row className="mt-2">
                    <Col>Taker Fee:</Col>
                    <Col>
                        <Input 
                            className="input-group input-group-sm" 
                            id="takerfee" 
                            type="text" 
                            defaultValue={this.state.simulationParams.feeTaker}
                            size="sm"
                            onChange={this.onChangeSimulationParamsfeeTaker}
                            >
                        </Input>
                    </Col>
                </Row>

                <Row className="mt-2">
                    <Col>Using Fee:</Col>
                    <Col>
                        <Input 
                            className="input-group input-group-sm" 
                            id="usingfee" 
                            type="text" 
                            defaultValue={this.state.simulationParams.feeUsing}
                            size="sm"
                            onChange={this.onChangeSimulationParamsfeeUsing}
                            >
                        </Input>
                    </Col>
                </Row>

                <Row className="mt-2">
                    <Col>Slippage:</Col>
                    <Col>
                        <Input 
                            className="input-group input-group-sm" 
                            id="slippage" 
                            type="text" 
                            defaultValue={this.state.simulationParams.slippage}
                            size="sm"
                            onChange={this.onChangeSimulationParamsSlippage}
                            >
                        </Input>
                    </Col>
                </Row>

                <Row className="mt-3">
                    <Col><Label><h4>Balance for simulation</h4></Label></Col>
                </Row>

                <Row className="mt-2">
                    <Col>Currency:</Col>
                    <Col>
                        <Input 
                            className="input-group input-group-sm" 
                            id="currency" 
                            type="text" 
                            defaultValue={this.state.simulationParams.simulationBalance.currency}
                            size="sm"
                            onChange={this.onChangeSimulationParamsCurrency}
                            >
                        </Input>
                    </Col>
                </Row>

                <Row className="mt-2">
                    <Col>Asset:</Col>
                    <Col>
                        <Input 
                            className="input-group input-group-sm" 
                            id="asset" 
                            type="text" 
                            defaultValue={this.state.simulationParams.simulationBalance.asset}
                            size="sm"
                            onChange={this.onChangeSimulationParamsAsset}
                            >
                        </Input>
                    </Col>
                </Row>
            

            </Container>
        
            }
        </div>
        )
    }
}

export default BotsSimulationsConf;


