//import Login from "components/Footers/AuthFooter";
import React, { Component } from 'react';
//import { reduxForm, Field } from 'redux-form';
//import { compose } from 'redux';
//import { connect } from 'react-redux';

//import { Link, NavLink } from "react-router-dom";
import moment from 'moment';

// reactstrap components
import {
    //  Button,
    //  Card,
    //  CardHeader,
    //  CardBody,
    //  FormGroup,
    //  Form,
    //  Input,
    //  InputGroupAddon,
    //  InputGroupText,
    //  InputGroup,
    //  Row,
    //  Col,
    Table,
} from "reactstrap";


class BotsTradesHistory extends Component {

    constructor() {
        super();
        //console.log("Component botslist")
        this.state = {
            roundtrip: [],
            isLoaded: false,
            isFetching: false,
        }
    }

    componentDidMount = () => {
        //console.log("componentDidMOunt for BotsTradeHistory")
        if (!this.state.roundtrip.length) {
            //onsole.log("Roundtrip State is not set: ")
            this.setState({ isFetching: true })
            this.setState({ isLoaded: false })
            ////console.log("isFetching " + this.state.isFetching)
            ////console.log("isLoaded " + this.state.isLoaded)
            if (this.props.roundtrip !== 'empty') {
                let history = this.props.roundtrip.slice()
                this.setState({ roundtrip: history })
            }
            this.setState({ isLoaded: true, isFetching: false })
            ////console.log("isFetching " + this.state.isFetching)
            ////console.log("isLoaded " + this.state.isLoaded)
        }
    }

    arrowup() {
        return (
            <i className="fas fa-arrow-down text-warning mr-3" />
        )
    };

    arrowdw() {
        return (
            <i className="fas fa-arrow-up text-success mr-3" />
        )
    };

 


    listTradesAll = () => {
        ////console.log("listTradesAll")
        ////console.log(this.props.roundtrip)
        function calculate_duration(duration) {
            //console.log("function for duration")
            let dr = ''
            let diffDuration = moment.duration(duration)
            if (diffDuration.minutes()) { dr = diffDuration.minutes() + " minutes" }
            if (diffDuration.hours()) { dr = diffDuration.hours() + " hours " + dr }
            if (diffDuration.days()) { dr = diffDuration.days() + " days " + dr }
            if (diffDuration.months()) { dr = diffDuration.months() + " months " + dr }
            if (diffDuration.years()) { dr = diffDuration.years() + " years " + dr }
            return dr
        }

        if (this.props.roundtrip !== 'empty' || typeof this.props.roundtrip === 'object') {

            return (


                this.props.roundtrip.map((d, k) => (


                    <tr id={k}>
                        <td>{moment(d.entryAt).format('YYYY-MM-DD HH:mm')}</td>
                        <td>{moment(d.exitAt).format('YYYY-MM-DD HH:mm')}</td>
                        <td>{
                            calculate_duration(d.duration)
                        }</td>
                        <td>{parseFloat(d.entryBalance).toFixed(2)}</td>
                        <td>{parseFloat(d.exitBalance).toFixed(2)}</td>
                        <td>
                            {parseFloat(d.pnl).toFixed(2)}</td>
                        <td>{(d.profit < 0) ? this.arrowup() : this.arrowdw()} {parseFloat(d.profit).toFixed(2)}%</td>
                    </tr>

                )
                )
            )
        }

    }

    render() {

        return (
            <div>

                {this.state.isLoaded &&
                    <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Entry at (UTC)</th>
                                <th scope="col">Exit at (UTC)</th>
                                <th scope="col">Exposure</th>
                                <th scope="col">Entry Balance</th>
                                <th scope="col">Exit Balance</th>
                                <th scope="col">PnL</th>
                                <th scope="col">Profit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.listTradesAll()}
                        </tbody>
                    </Table>
                }
            </div>

        )
    }
}

export default BotsTradesHistory;


