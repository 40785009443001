import { AUTH_ERROR, AUTH_USER, AUTH_USER_CREDENTIALS } from '../actions/types'
const INITIAL_STATE = {
    user_credentials:'',
    authenticate: '',
    errorMessage: ''
};

export default function auth(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AUTH_USER:
            return { ...state, authenticated: action.payload };
        case AUTH_ERROR:
            return { ...state, errorMessage: action.payload };
        case AUTH_USER_CREDENTIALS:
            return { ...state, user_credentials: action.payload };
        default:
            return state;
    }
};
