/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
// node.js library that concatenates classes (strings)
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { withRouter } from "react-router-dom";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Container,
    Input,
    Form,
    Label,
    Row,
    FormGroup,
    Col,
} from "reactstrap";

// core components
import axios from 'axios';

import Header from "components/Headers/Header.js";
import './index.css'
import Switch from '@mui/material/Switch';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const Index = (props) => {
    const [month, setMonth] = useState([])
    const [months, setMonths] = useState([])
    const [perioada, setPerioada] = useState(true)
    const [prices, setPrices] = useState([])

    useEffect(async () => {
        await axios.get(
            process.env.REACT_APP_URL_Orchestrator + '/packages',
            {
                headers: {
                    "Authorization": " Bearer " + props.authenticated,
                    "content-type": "application/json"
                }
            }

        ).then(response => {
            var Oluna = [];
            var Dluni = [];
            response.data.map(element => {
                if (element.shortname != "bronze") {
                    if (element.months == 1) Oluna.push(element)
                    else Dluni.push(element)
                }
            })

            Oluna.sort((a, b) => (a.amount > b.amount) ? 1 : ((b.amount > a.amount) ? -1 : 0))
            Dluni.sort((a, b) => (a.amount > b.amount) ? 1 : ((b.amount > a.amount) ? -1 : 0))

            setMonth(Oluna);
            setMonths(Dluni)
            setPrices(Dluni)


        }).catch(err => {
            console.log(err.response)
            if (err.response) {
                if (err.response.status > 401) { console.log("test") }
            }
        })


    }, []);

    const changePrices = () => {
        if (perioada == true) {
            setPrices(month)
            setPerioada(false)
        } else {
            setPrices(months)
            setPerioada(true)
        }
    }
    const sendRequest = async (data) => {
        var raw = {
            "user_id": props.user_credentials.user_id,
            "currency": "USD",
            "package_shortname": data.shortname
        };

        await axios.post(process.env.REACT_APP_URL_Orchestrator + "/order", raw,
            {
                headers: {
                    "Authorization": " Bearer " + props.authenticated,
                    "content-type": "application/json"
                }
            })

            .then(result => {
                var resp = result.data
                console.log(resp.payment_url)
                window.open(resp.payment_url)
            }).catch(err => {
                console.log(err)
                if (err.response) {
                    if (err.response.status >= 401) { console.log("test") }
                }
            })

    }
    return (
        <>
            <Header />
            <Container className="mt--7" fluid>
                <Row className="mt-12">
                    <Col className="mb-5 mb-xl-0" xl="12">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <center><h1 className="mb-0" style={{ fontSize: "22" }}>Pricing</h1></center><br />
                                        <center>
                                            <span>One Month</span>
                                            <Switch {...label} defaultChecked onClick={(e) => changePrices()} />
                                            <span>Two Months</span>
                                        </center>
                                    </div>
                                    <div>
                                        <i className="fas fa-screwdriver text-success fa-2x"></i>
                                    </div>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {prices.length > 0 ?
                                    <Row>
                                        <Col xl="4">
                                            <CardBody className="card-margine">
                                                <center><h1>{prices[0].title}</h1>
                                                    <h2>{prices[0].amount} <span>USDT</span> </h2>
                                                    <ul style={{ listStyleType: "none" }}>
                                                        <li>{prices[0].max_tradebots} traderbots</li>
                                                        <li>{prices[0].max_papertraders} paperTrader</li>

                                                    </ul>
                                                    <Button id={0} color="secondary" onClick={(e) => { sendRequest(prices[0]) }}>Pay Now</Button></center>
                                            </CardBody>
                                        </Col>
                                        <Col xl="4">
                                            <CardBody className="card-centru">
                                                <center><h1>{prices[1].title}</h1>
                                                    <h2>{prices[1].amount} <span>USDT</span> </h2>
                                                    <ul style={{ listStyleType: "none" }}>
                                                        <li>{prices[1].max_tradebots} traderbots</li>
                                                        <li>{prices[1].max_papertraders} paperTrader</li>

                                                    </ul>
                                                    <Button id={1} color="secondary" onClick={(e) => { sendRequest(prices[1]) }}>Pay Now</Button></center>
                                            </CardBody>
                                        </Col>
                                        <Col xl="4">
                                            <CardBody className="card-margine">
                                                <center><h1>{prices[2].title}</h1>
                                                    <h2>{prices[2].amount} <span>USDT</span> </h2>
                                                    <ul style={{ listStyleType: "none" }}>
                                                        <li>{prices[2].max_tradebots} traderbots</li>
                                                        <li>{prices[2].max_papertraders} paperTrader</li>

                                                    </ul>  <Button id={2} color="secondary" onClick={(e) => { sendRequest(prices[2]) }}>Pay Now</Button></center>

                                            </CardBody>
                                        </Col>
                                    </Row> : null}

                            </CardBody>

                        </Card>


                    </Col>

                </Row>
            </Container>
        </>
    );
};

function mapStateToProps(state) {
    return { authenticated: state.auth.authenticated, user_credentials: state.auth.user_credentials };
}


export default compose(
    connect(mapStateToProps, actions)
)(withRouter(Index));
