//import Login from "components/Footers/AuthFooter";
import React, { Component } from 'react';
//import { reduxForm, Field } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../../../actions'
//import { Link, NavLink } from "react-router-dom";
//import Header from '../Headers/Header';
import axios from 'axios';

// reactstrap components
import {
    Input,
    //  InputGroupAddon,
    //  InputGroupText,
    //  InputGroup,
    Row,
    Col,
    Container,
    Label,
} from "reactstrap";



class BotsExchange extends Component {

    constructor(props) {
        //console.log("Constructor de Markets")
        super(props);

        this.state = {
            market: null,
            marketArray: [],
            currency: null,
            currencyArray: [],
            asset: null,
            assetArray: [],
            dataTree: [],
            marketSelect: [],
            loading: false,
            currentObj: {},
            watch: {
                exchange: '',
                currency: '',
                asset: ''
            }
        };
    }

    componentDidMount() {
        this.setState({
            loading: true
        })

        var myHeaders = new Headers();
        myHeaders.append("accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + this.props.authenticated);
        myHeaders.append("Content-Type", "application/json");



        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL_Gekko + '/api/gekko/' + this.props.user_credentials.user_id + '/' + this.props.user_credentials.bot_id + "/api/exchanges", requestOptions)
            .then(response => {
                if (response.status === 401) { console.log("test") }
                else { return response.json() }
            })
            .then(result => {
                var arr = [];
                var arrMarketSelect = [];
                result.map((element) => {
                    var objMarketSelect = {
                        value: element.name,
                        label: element.name
                    }
                    arrMarketSelect.push(objMarketSelect)
                    ////
                    var obj = {}
                    obj.name = element.name
                    obj[element.name] = { currencies: element.currencies }
                    obj[element.name].currencies.map(eachcurrency => {
                        var arrAssets = [];
                        element.markets.map(pereche => {
                            if (pereche.pair[0] == eachcurrency) {
                                arrAssets.push(pereche.pair[1])
                            }
                        })
                        obj[element.name][eachcurrency] = arrAssets;
                    })
                    arr.push(obj)
                })
                var firstMarket = arrMarketSelect[0].value;
                var market_1 = arr[0]

                var firstCurrency = arr[0][firstMarket].currencies[0];
                this.setState({
                    dataTree: arr,
                    marketSelect: arrMarketSelect,
                    market: arrMarketSelect[0].name,
                    currency: arr[0][firstMarket].currencies[0],
                    asset: arr[0][firstMarket][firstCurrency][0],
                    assetArray: arr[0][firstMarket][firstCurrency],
                    currencyArray: arr[0][firstMarket].currencies,
                    loading: false
                })
                let watch = {
                    exchange: arrMarketSelect[0].name,
                    currency: arr[0][firstMarket].currencies[0],
                    asset: arr[0][firstMarket][firstCurrency][0],
                }
                this.props.updateConf(watch)

            })
            .catch(error => console.log('error', error));


    }

    onSelectMarket = (e) => {
        this.setState({ market: e.target.value })
        var marketValue = e.target.value;
        var objMarket = this.state.dataTree.find(e => e.name == marketValue)
        var firstCurrency = objMarket[marketValue].currencies[0];
        this.setState({
            currencyArray: objMarket[marketValue].currencies,
            currentObj: objMarket[marketValue],
            currency: objMarket[marketValue].currencies[0],
            assetArray: objMarket[marketValue][firstCurrency],
            asset: objMarket[marketValue][firstCurrency][0]
        })
        let watch = {
            exchange: e.target.value,
            currency: objMarket[marketValue].currencies[0],
            asset: objMarket[marketValue][firstCurrency][0]
        }
        this.props.updateConf(watch)

    }

    onSelectAsset = (e) => {
        this.setState({ asset: e.target.value })
        let watch = {
            exchange: this.state.market,
            currency: this.state.currency,
            asset: e.target.value
        }
        this.props.updateConf(watch)
    }

    onSelectCurrency = (e) => {
        this.setState({ currency: e.target.value })
        var currency = e.target.value;
        this.setState({
            assetArray: this.state.currentObj[currency],
            asset: this.state.currentObj[currency][0]
        })
        let watch = {
            exchange: this.state.market,
            currency: e.target.value,
            asset: this.state.currentObj[currency][0]
        }
        this.props.updateConf(watch)
    }



    render() {

        return (

            <div>
                {this.state.loading===false &&
                    <Container>
                        <Row>
                            <Col>
                                <Label for="exampleSelect">Exchanges:</Label>
                                <Input
                                    type="select"
                                    name="select"
                                    id="exampleSelect"
                                    className="input-group select-group-sm"
                                    bsSize="sm"
                                    onChange={this.onSelectMarket}
                                >
                                    {
                                        this.state.marketSelect.map((d, index) => {
                                            return (<option key={index}>{d.value}</option>)
                                        })
                                    }
                                </Input>
                            </Col>

                        </Row>
                        <Row className="mt-2">
                            <Col>

                                <Label for="exampleSelect">Currency:</Label>
                                <Input
                                    type="select"
                                    name="select"
                                    id="exampleSelect"
                                    className="input-group select-group-sm"
                                    bsSize="sm"
                                    onChange={this.onSelectCurrency}
                                >
                                    {
                                        this.state.currencyArray.map((d, index) => {
                                            return (<option key={index}>{d}</option>)
                                        })
                                    }
                                </Input>
                            </Col>
                            <Col>

                                <Label for="exampleSelect">Asset:</Label>
                                <Input
                                    type="select"
                                    name="select"
                                    id="exampleSelect"
                                    className="input-group select-group-sm"
                                    bsSize="sm"
                                    onChange={this.onSelectAsset}
                                >
                                    {
                                        this.state.assetArray.map((d, index) => {
                                            return (<option key={index}>{d}</option>)
                                        })
                                    }
                                </Input>
                            </Col>
                        </Row>

                    </Container>
                }
            </div>

        )
    }
}
function mapStateToProps(state) {
    return { authenticated: state.auth.authenticated, user_credentials: state.auth.user_credentials };
}

export default compose(
    connect(mapStateToProps, actions)
)(BotsExchange);



