/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";

import { useSelector } from 'react-redux'
import mainLogo from './exclamationSign.png';

const Profile = () => {

  const [newMail, setNewMail] = useState(null);
  const [errNewMail, setErrNewMail] = useState(null);
  const token = useSelector((state) => state.auth.authenticated)
  const [oldPassword, setOldPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [retypePassword, setRetypePassword] = useState(null);
  const [errOldPassword, setErrOldPassword] = useState(null);
  const [errNewPassword, setErrNewPassword] = useState(null);
  const [errRetypePassword, setErrRetypePassword] = useState(null);


  const changeMail = () => {
    if (errNewMail === null && newMail) {
      var myHeaders = new Headers();
      myHeaders.append("accept", "application/json");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append('Authorization', "Bearer " + token)

      var raw = JSON.stringify({
        "email": newMail
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(process.env.REACT_APP_URL_Orchestrator + "/flows/change-email", requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
    } else {
      setErrNewMail("Insert a valid e-mail")
    }
  }
  const checkMail = () => {
    var myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json")

    var raw = JSON.stringify({
      "email": newMail
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_URL_Orchestrator + "/user/validate-email", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.message === 'Valid') { setErrNewMail(null) }
        else { setErrNewMail('Insert a valid e-mail') }
      })
      .catch(error => console.log('error', error));
  }
  const changePassword = () => {
    //console.log('change password')
    if (!errNewPassword && !errOldPassword && !errRetypePassword) {
      if (!newPassword || !oldPassword || !retypePassword) {
        if(!newPassword){setErrNewPassword('Insert new password')}
        if(!oldPassword){setErrOldPassword('Insert old password')}
        if(!retypePassword){setErrRetypePassword('Insert old password')}
      } else {
        if(newPassword===retypePassword){
        var myHeaders = new Headers();
        myHeaders.append("accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + token);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          "old_password": oldPassword,
          "new_password": newPassword
        });

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL_Orchestrator + "/flows/change-password", requestOptions)
          .then(response => response.json())
          .then(result => {
            if(result.detail){
              setErrOldPassword(result.detail)
            }
          })
          .catch(error => console.log('error', error));
      }else{
        setErrRetypePassword('Retyped password must be the same with new password')
      }
      }
    }
  }

  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>

          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">My account</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    User information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            First Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            placeholder="Insert first name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Last Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            placeholder="Inser last name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                </Form>
                <Button
                  color="primary "
                  style={{ float: 'right' }}
                >
                  Save user informations
                </Button>
              </CardBody>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    Change Mail
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            New E-mail
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            placeholder="Insert new e-mail"
                            type="text"
                            value={newMail}
                            onChange={(e) => setNewMail(e.target.value)}
                            onBlur={(e) => checkMail()}
                          />
                          {errNewMail ? <div style={{ marginLeft: 5, marginTop: 5 }}> <img src={mainLogo} style={{ width: "16px", height: "16px", marginLeft: "5px" }} /> <span style={{ color: "red", display: 'inline-block' }}>{errNewMail}</span></div> : null}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                </Form>
                <Button
                  color="primary"
                  style={{ float: 'right' }}
                  onClick={() => { changeMail() }}
                >
                  Change Mail
                </Button>
              </CardBody>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    Change Password
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Old Password
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            placeholder="Insert old password"
                            type="password"
                            onChange={(e) => { setOldPassword(e.target.value) }}
                            onBlur={() => {
                      
                              if (oldPassword === null || oldPassword === '') setErrOldPassword('Insert old password')
                                else setErrOldPassword(null)
                            }}
                          />
                          {errOldPassword ? <div style={{ marginLeft: 5, marginTop: 5 }}> <img src={mainLogo} style={{ width: "16px", height: "16px", marginLeft: "5px" }} /> <span style={{ color: "red", display: 'inline-block' }}>{errOldPassword}</span></div> : null}
                        </FormGroup>
                      </Col>

                    </Row>
                  </div>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            New Password
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            placeholder="Password"
                            type="password"
                            onChange={(e) => { setNewPassword(e.target.value) }}
                            onBlur={() => {
                              if (newPassword === null || newPassword === '') setErrNewPassword('Insert new password')
                              else {
                                if (newPassword.length < 7) setErrNewPassword('Password must be al leath 7 characters')
                                    else setErrNewPassword(null)
                              }
                            }}
                          />
                          {errNewPassword ? <div style={{ marginLeft: 5, marginTop: 5 }}> <img src={mainLogo} style={{ width: "16px", height: "16px", marginLeft: "5px" }} /> <span style={{ color: "red", display: 'inline-block' }}>{errNewPassword}</span></div> : null}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Retype New Password
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            placeholder="Retype Password"
                            type="password"
                            onChange={(e) => setRetypePassword(e.target.value)}
                            onBlur={() => {
                              if (retypePassword == null || retypePassword === '') setErrRetypePassword('Insert new password')
                              else {
                                if (retypePassword.length < 7) setErrRetypePassword('Retyped password must be al leath 7 characters')
                                    else setErrRetypePassword(null)
                              }
                            }}
                          />
                          {errRetypePassword ? <div style={{ marginLeft: 5, marginTop: 5 }}> <img src={mainLogo} style={{ width: "16px", height: "16px", marginLeft: "5px" }} /> <span style={{ color: "red", display: 'inline-block' }}>{errRetypePassword}</span></div> : null}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
                <Button
                  color="primary "
                  style={{ float: 'right' }}
                  onClick={() => { changePassword() }}
                >
                  Save new password
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
