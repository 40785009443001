
import React, { Component, useState } from 'react';
import { reduxForm, Field } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";

import mainLogo from './exclamationSign.png';
import './index.css';
import asyncValidate from './asyncValidate';




const renderField = ({
  input,
  label,
  type,
  meta: { touched, error, warning }
}) => (
  <div style={{ width: "89%" }}>
    <Input {...input} placeholder={label} type={type} />
    {touched && ((error && <div> <img src={mainLogo} style={{ width: "16px", height: "16px", marginLeft: "5px" }} /> <span style={{ color: "red", display: 'inline-block' }}>{error}</span></div>) || (warning && <span>{warning}</span>))}
  </div>
)


const required = value => value ? undefined : 'Required'
const email = value => isEmail(value) ? undefined : 'Mail invalid'
const username = value => isUsername(value) ? undefined : 'Username already exists'
const minValue = min => value => value !== null && value.length < min ? `Must be at least ${min} characters` : undefined
const minValue3 = minValue(3);
const minValue6 = minValue(6);
const minValue7 = minValue(7);


const isEmail = (value) => {
  //console.log(value.target.value)
  return true
}

const isUsername = (value) => {
  //console.log(value)
  return true
}
const Register = props => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    props.history.push('/auth/login');
  }
  const onSubmit = formProps => {
    //console.log('ttttttttttttttttttttttttttttttttttttttttttttt')
    var myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "first_name": formProps.first_name,
      "last_name": formProps.last_name,
      "email": formProps.email,
      "language": "en_GB",
      "username": formProps.username,
      "password": formProps.password
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_URL_Orchestrator + "/user", requestOptions)
      .then(result => {
        if (result.status == 200) {
          openModal();
        }
        var response = result.json();
        return response;
      })
      .catch((err) => {
        //console.log(err)
        //tratarea erorilor
      })
  };
  const { handleSubmit } = props;

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        toggle={closeModal}
        className="modal"
        fade={true}
        style={{ opacity: 5, display: "block" }}
      >
        <ModalHeader ><div style={{ fontSize: 25 }}>Email validation</div></ModalHeader>
        <ModalBody>
          <center>
            Please check your email for account validation!<br></br>
            Thank you!
          </center>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => closeModal()}>OK</Button>{' '}
        </ModalFooter>
      </Modal>

      <div className="marimi">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Sign up with Trader Bots credentials</small>
            </div>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                <div style={{ display: "flex" }}>
                  <i className="ni ni-hat-3" style={{ marginTop: "11px", marginLeft: "8px", marginRight: "8px" }} />
                  <Field
                    className='form-control'
                    name="first_name"
                    type="text"
                    component={renderField}
                    validate={[required, minValue3]}
                    autoComplete="none"
                    label="First Name"
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <div style={{ display: "flex" }}>
                  <i className="ni ni-hat-3" style={{ marginTop: "11px", marginLeft: "8px", marginRight: "8px" }} />
                  <Field
                    className='form-control'
                    name="last_name"
                    type="text"
                    component={renderField}
                    validate={[required, minValue3]}
                    autoComplete="none"
                    label="Last Name"
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <div style={{ display: "flex" }}>
                  <i className="ni ni-circle-08" style={{ marginTop: "11px", marginLeft: "8px", marginRight: "8px" }} />

                  <Field
                    className='form-control'
                    name="username"
                    type="text"
                    component={renderField}
                    validate={[required, minValue6]}
                    autoComplete="none"
                    label="Username"

                  />
                </div>
              </FormGroup>
              <FormGroup>
                <div style={{ display: "flex" }}>
                  <i className="ni ni-email-83" style={{ marginTop: "11px", marginLeft: "8px", marginRight: "8px" }} />
                  <Field
                    className='form-control'
                    name='email'
                    component={renderField}
                    validate={[required]}
                    type="text"
                    label='Email'
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <div style={{ display: "flex" }}>
                  <i className="ni ni-lock-circle-open" style={{ marginTop: "11px", marginLeft: "8px", marginRight: "8px" }} />

                  <Field
                    className='form-control'
                    name='password'
                    component={renderField}
                    validate={[required, minValue7]}
                    type="password"
                    label='Password'
                  />
                </div>

              </FormGroup>
              <Row className="my-4">
                <Col xs="12">
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="customCheckRegister"
                      type="checkbox"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheckRegister"
                    >
                      <span className="text-muted">
                        I agree with the{" "}
                        <a href="#ok" onClick={(e) => e.preventDefault()}>
                          Privacy Policy
                        </a>
                      </span>
                    </label>
                  </div>
                </Col>
              </Row>
              <div className="text-center">
                <Button className="mt-4" color="primary" type="submit">
                  Create account
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

//export default Register;
export default compose(
  connect(null, actions),
  reduxForm({ form: 'signup', asyncValidate, asyncBlurFields: ['username', 'email'], })
)(Register);