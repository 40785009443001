/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.js";
import Profile from "views/examples/Profile.js";
import Maps from "views/examples/Maps.js";
import Register from "views/examples/Register.js";
import Login from "views/examples/Login.js";
import Tables from "views/examples/Tables.js";
import Icons from "views/examples/Icons.js";
import Bots from "components/Bots/index";
import Backtest from "components/Backtest/index";
import LocalData from "components/LocalData/index";
import BotsGet from "components/Bots/BotsGet";
import AddLocalData from "components/LocalData/DataSetImporterAdd";
import Config from 'components/Config/index.js';
import DatasetImporterView from "components/LocalData/DataSetImporterView";
import Pricing from "./components/pricing";
import BacktestView from "components/Backtest/BacktestView/BacktestView";
import BotAdd from './components/Bots/BotsAdd'

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-blue",
    component: Index,
    layout: "/admin",
    visible: true,
  },
  {
    path: "/maps",
    name: "Maps",
    icon: "ni ni-pin-3 ",
    component: Maps,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 ",
    component: Profile,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/tables",
    name: "Tables",
    icon: "ni ni-bullet-list-67 ",
    component: Tables,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 ",
    component: Login,
    layout: "/auth",
    visible: false
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 ",
    component: Register,
    layout: "/auth",
    visible: false,
  },
  {
    path: "/service/bots",
    name: "Bots",
    icon: "ni ni-spaceship",
    component: Bots,
    layout: "/admin",
    visible: true,
  },
  {
    path: "/service/botsview",
    name: "Bot View",
    icon: "ni ni-spaceship",
    component: BotsGet,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/service/backtest",
    name: "Backtest",
    icon: "ni ni-planet ",
    component: Backtest,
    layout: "/admin",
    visible: true,
  },
  {
    path: "/service/backtestView",
    name: "Backtest View",
    icon: "ni ni-spaceship",
    component: BacktestView,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/service/botAdd",
    name: "Bot Add",
    icon: "ni ni-spaceship",
    component: BotAdd,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/service/localdata",
    name: "Local Data",
    icon: "ni ni-basket ",
    component: LocalData,
    layout: "/admin",
    visible: true,
  },
  {
    path: "/service/addLocalData",
    name: "Add LocalData",
    component: AddLocalData,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/importer",
    name: "Importer",
    component: DatasetImporterView,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/service/config",
    name: "Config",
    icon: "ni ni-settings ",
    component: Config,
    layout: "/admin",
    visible: true,
  },
  {
    path: "/pricing",
    name: "Prices",
    icon: "ni ni-money-coins text-green",
    component: Pricing,
    layout: "/admin",
    visible: true,
  },
 
];
export default routes;
