
import React, { Component } from 'react';
//import { reduxForm, Field } from 'redux-form';
//import { compose } from 'redux';
//import { connect } from 'react-redux';
//import * as actions from '../../actions'
//import { Link, NavLink } from "react-router-dom";
//import BotsSimulationsConf from "components/Bots/BotsAdd/BotsConf/BotsSimulationConf";
//import BotsParameters from "components/Bots/BotsAdd/BotsConf/BotsParameters"
import BacktestResultData from "../BacktestResultData/BacktestResultData";
//import BotsMarketGraphic from "../Bots/BotsGet/BotsMarketGraphic";
import BacktestMarketGraphic from "../BacktestMarketGraphic/BacktestMarketGraphic";
//import BotsTradesHistory from "components/Bots/BotsGet/BotsTradesHistory";
import BacktestTradesHistory from '../BacktestTradesHistory/BacktestTradesHistory';
import Header from '../../Headers/Header';
import axios from 'axios';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../../actions';

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    Container,
    Spinner
} from "reactstrap";


class BacktestView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "visible",
            backtest_bot: this.props.location.state.bot,
            backtest_result: '',
            backtest_strategy: '',
            isFetching: false,
            isLoaded: false,
        };

    }
    async componentDidMount() {
        try {
            this.setState({ isFetching: true, isLoaded: false })
            const headers = {
                "Authorization": "Bearer " + this.props.authenticated,
                "content-type": "application/json"
            };

            console.log(this.props)
            let jsondata = this.props.location.state.bot

            //console.log(jsondata)

            await axios.post(process.env.REACT_APP_URL_Gekko + '/api/gekko/' + this.props.user_credentials.user_id + '/' + this.props.user_credentials.bot_id + '/api/backtest',
                jsondata,
                { headers: headers })
                .then(res => {
                    //console.log(res.data)
                    this.setState({ backtest_result: res.data, isFetching: false, isLoaded: true })
                }
                ).catch(err => {
                    if (err.response.status === 401) { console.log("test") }
                })
        } catch {
            this.setState({ isFetching: false })
        }
    }

    render() {


        return (
            <div>
                <Header />
                <Container className="mt--7" fluid>
                {this.state.isLoaded === true ?
                    <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12">
                           
                                <Card className="shadow">
                                    <CardHeader className="border-0">
                                        <Row className="align-items-center">
                                            <div className="col">
                                                <h3 className="mb-0">Backtest results</h3>
                                            </div>
                                            <div className="col-md-6 text-right">
                                                <i className="fas fa-hand-holding-usd text-success fa-2x"></i>
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <BacktestResultData data={this.state.backtest_result.performanceReport} isLoaded={this.state.isLoaded} />
                                    </CardBody>
                                </Card>
                               
                        </Col>
                    </Row>
                    : null}
                    {this.state.isLoaded === true ?
                    <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12">
                      
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Market Chart</h3>
                                        </div>
                                        <div className="col-md-6 text-right">
                                            <i className="fas fa-chart-line text-success fa-2x"></i>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                        <BacktestMarketGraphic stratCandles={(typeof this.state.backtest_result.stratCandles !== 'undefined') ?
                                            this.state.backtest_result.stratCandles : "empty"
                                        }
                                            prices={(typeof this.state.backtest_result.trades !== 'undefined') ?
                                                this.state.backtest_result.trades : "empty"
                                            }
                                        />
                                        
                                </CardBody>
                            </Card>
                           
                        </Col>
                    </Row>
                     : null}
                    <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Trades History</h3>
                                        </div>
                                        {this.state.isFetching ?
                                            <center>
                                                <div>
                                                    <Spinner color="success" />
                                                </div>
                                            </center>
                                            : null}
                                        <div className="col-md-6 text-right">
                                            <i className="fas fa-balance-scale text-success fa-2x"></i>
                                        </div>


                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    {this.state.isLoaded === true ?

                                        <BacktestTradesHistory
                                            roundtrip={(typeof this.state.backtest_result.roundtrips !== 'undefined') ?
                                                this.state.backtest_result.roundtrips : "empty"
                                            }
                                            isLoaded={this.state.isLoaded}
                                            isFetching={this.state.isFetching}
                                        />
                                        : null}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return { authenticated: state.auth.authenticated, user_credentials: state.auth.user_credentials };
}

export default compose(
    connect(mapStateToProps, actions)
)(BacktestView);



