import React, { Component } from "react";
//import ReactDOM from "react-dom";
import { Route, Redirect } from "react-router-dom";
//import { createStore, applyMiddleware } from 'redux';
//import reduxThunk from 'redux-thunk';
//import { Provider } from 'react-redux';

//import reducers from './reducers';
//import Root from './root';

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { connect } from 'react-redux';
import Login from './views/examples/Login'



class RootRoutes extends Component {
    renderRoutes() {
        console.log(this.props.authenticated)
        if (this.props.authenticated) {
            return (
                <div>
                    <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
                    <Redirect from="/" to="/admin/index" />
                </div>
            )
        } else {
            return (
                <div>
                   
                    <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
                    <Redirect from="*" to="/auth/login" />
                </div>
            )
        }
    }

    render() {
        return (
            <div>
                {this.renderRoutes()}
            </div>
        )
    };
};

function mapStateToProps(state) {
    return { authenticated: state.auth.authenticated };
}

export default connect(mapStateToProps)(RootRoutes);