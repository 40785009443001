
//import { tsvParse, csvParse } from  "d3-dsv";
// import { timeParse } from "d3-time-format";
// import { compose } from 'redux';
// import { withRouter } from "react-router-dom";
// import { connect } from 'react-redux';

// import * as actions from './../../../actions'

// function tsvJSON(tsv) {

// 	var lines = tsv.split("\n");
// 	var result = [];
// 	var headers = lines[0].split("\t");
// 	for (var i = 1; i < lines.length; i++) {
// 		var obj = {};
// 		var currentline = lines[i].split("\t");
// 		for (var j = 0; j < headers.length; j++) {
// 			obj[headers[j]] = currentline[j];
// 		}
// 		result.push(obj);
// 	}
// 	//return result; //JavaScript object
// 	return JSON.stringify(result); //JSON
// }

// function parseData(parse) {
// 	return function (d) {
// 		d.date = parse(d.date);
// 		d.open = +d.open;
// 		d.high = +d.high;
// 		d.low = +d.low;
// 		d.close = +d.close;
// 		d.volume = +d.volume;

// 		return d;
// 	};
// }

// const parseDate = timeParse("%Y-%m-%d");

export const getData = (user_id, bot_id, token,exchange,currency,asset,start,stop) => {
	var myHeaders = new Headers();
	myHeaders.append("accept", "application/json");
	myHeaders.append("Authorization", "Bearer " + token);
	myHeaders.append("Content-Type", "application/json");

	var raw = JSON.stringify({
		"watch": {
			"exchange": exchange,
			"currency":currency,
			"asset": asset
		},
		"daterange": {
			"to": stop,
			"from": start
		},
		"candleSize": 60
	});

	var requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow'
	};

	const promiseMSFT = fetch(process.env.REACT_APP_URL_Gekko + '/api/gekko/' +user_id + '/' + bot_id + "/api/getCandles", requestOptions)
		.then(response => {
			if (response.status === 401) { console.log("test") }
			else { return response.text() }
		})
		 .then(result => {return result})

	return promiseMSFT;
}
