//import Login from "components/Footers/AuthFooter";
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
//import { reduxForm, Field } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions'
import { Link, NavLink } from "react-router-dom";
import Header from '../Headers/Header';


// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    Table,
    CardBody,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    Input,
    Container,
    //    Progress,
    Spinner,
} from "reactstrap";

const marketOptions = [
    { value: '', label: '' },
    { value: 'poloniex', label: 'Poloniex' },
    { value: 'binance', label: 'Binance' },
    { value: 'bitfinex', label: 'Bitfinex' },
    { value: 'exmo', label: 'Exmo' },
    { value: 'gdax', label: 'Gdax' },
    { value: 'kraken', label: 'Kraken' },
    { value: 'luno', label: 'Luno' },
    { value: 'coinfalcon', label: 'Coinfalcon' },
];


class Config extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modalIsOpen: false,
            value: new Date().toISOString(),
            marketValue: '',
            apiKeys: [],
            selectedValues: [],
            loaded: false,
            secret: '',
            key: '',
            passphrase: '',
            marketValueError: '',
            keyError: '',
            secretError: '',
            passpgraseError: ''
        }
    }

    componentDidMount() {

        var myHeaders = new Headers();
        myHeaders.append("accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + this.props.authenticated);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        this.setState({ loaded: false })
        fetch(process.env.REACT_APP_URL_Gekko + '/api/gekko/' + this.props.user_credentials.user_id + '/' + this.props.user_credentials.bot_id +  "/api/apiKeys", requestOptions)
            .then(response => {
                if (response.status === 401) { console.log("test") }
                else { return response.json() }
            })
            .then(result => {
                this.setState({
                    apiKeys: [...result]
                })

                var difference = marketOptions.filter(x => !result.includes(x.value));
                this.setState({
                    selectedValues: [...difference]
                })
                this.setState({ loaded: true })

            })
            .catch(error => console.log('error', error));
    }
    openModal() {
        this.setState({
            modalIsOpen: true
        })

    }

    checkMarket() {
        if (!this.state.marketValue) { this.setState({ marketValueError: 'Please select one market!' }) }
        else { this.setState({ marketValueError: '' }) }
    }
    checkKey() {
        if (!this.state.key) { this.setState({ keyError: 'Please insert key!' }) }
        else { this.setState({ keyError: '' }) }
    }
    checkSecret() {
        if (!this.state.secret) { this.setState({ secretError: 'Please insert secret!' }) }
        else { this.setState({ secretError: '' }) }
    }
    checkPassphrase() {
        if (!this.state.passphrase) {
            this.setState({ passpgraseError: 'Please insert passphrase!' })
           
        }
        else { this.setState({ passpgraseError: '' }) }
    }


    closeModal() {
        this.setState({
            modalIsOpen: false
        })
    }
    deleteApiKey(apiKey) {
        var myHeaders = new Headers();
        myHeaders.append("accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + this.props.authenticated);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "exchange": apiKey

        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL_Gekko + '/api/gekko/' + this.props.user_credentials.user_id + '/' + this.props.user_credentials.bot_id + "/api/removeApiKey", requestOptions)
            .then(response => {
                if (response.status === 401) { console.log("test") }
                else { return response.text() }
            })
            .then(result => {
                var array = this.state.apiKeys
                const index = array.indexOf(apiKey);
                if (index > -1) {
                    array.splice(index, 1); // 2nd parameter means remove one item only
                }

                this.setState({
                    apiKeys: array
                })
                var difference = marketOptions.filter(x => !this.state.apiKeys.includes(x.value));
                this.setState({
                    selectedValues: [...difference]
                })
            })
            .catch(error => console.log('error', error));
    }
    listaApiKeys() {
        var listApiKeys = this.state.apiKeys.map((d, key) =>
            <tr key={key}>
                <td className="text-center">{d}</td>
                <td><center><button className="btn btn-danger btn-sm" onClick={(e) => { this.deleteApiKey(d) }}>Remove ApiKey</button></center></td>
            </tr>
        );
        return listApiKeys
    }
    submit() {
        var marketValueError='';
        var keyError='';
        var secretError='';
        var passpgraseError='';
        if (!this.state.marketValue) { this.setState({ marketValueError: 'Please select one market!' }); marketValueError= 'Please select one market!' }
        else { this.setState({ marketValueError: '' }) }
        if (!this.state.key) { this.setState({ keyError: 'Please insert key!' }); keyError= 'Please insert key!' }
        else { this.setState({ keyError: '' }) }
        if (!this.state.secret) { this.setState({ secretError: 'Please insert secret!' });secretError= 'Please insert secret!' }
        else { this.setState({ secretError: '' }) }
        if (this.state.marketValue === 'gdax') {
            if (!this.state.passphrase) {
                this.setState({ passpgraseError: 'Please insert passphrase!' })
                passpgraseError= 'Please insert passphrase!'
            }
            else { this.setState({ passpgraseError: '' }) }
        }


        if (passpgraseError === '' && marketValueError === '' && keyError === '' && secretError === '') {
            var myHeaders = new Headers();
            myHeaders.append("accept", "application/json");
            myHeaders.append("Authorization", "Bearer " + this.props.authenticated);
            myHeaders.append("Content-Type", "application/json");
            var raw = {}
            if (this.state.marketValue !== 'gdax') {
                raw = JSON.stringify({
                    "exchange": this.state.marketValue,
                    "values": {
                        "key": this.state.key,
                        "secret": this.state.secret,
                    }
                });
            } else {
                raw = JSON.stringify({
                    "exchange": this.state.marketValue,
                    "values": {
                        "key": this.state.key,
                        "secret": this.state.secret,
                        "passphrase": this.state.passphrase
                    }
                });
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL_Gekko + '/api/gekko/' + this.props.user_credentials.user_id + '/' + this.props.user_credentials.bot_id +"/api/addApiKey", requestOptions)
                .then(response => {
                    if (response.status === 401) { console.log("test") }
                    else { return response.text() }
                })
                .then(result => {
                    this.setState({
                        apiKeys: [...this.state.apiKeys, this.state.marketValue]
                    })
                    var difference = marketOptions.filter(x => !this.state.apiKeys.includes(x.value));
                    this.setState({
                        selectedValues: [...difference]
                    })
                    this.setState({
                        secret: '',
                        passphrase: '',
                        key: ''
                    })
                    this.closeModal()

                })
                .catch(error => console.log('error', error));
        }
    }

    listMarket() {
        if (this.state && this.state.selectedValues) {
            return this.state.selectedValues.map((d, key) =>
                <option key={key}>{d.value}</option>
            );
        }
    }



    render() {

        return (

            <div>
               
                <Modal
                    isOpen={this.state.modalIsOpen}
                    toggle={() => { this.closeModal() }}
                    className="modal"
                    fade={true}
                    style={{ opacity: 5, display: "block" }}
                >

                    <div>
                        <ModalHeader ><div style={{ fontSize: 25 }}>Add ApiKey</div></ModalHeader>
                        <ModalBody>

                            <p> Select Market</p>
                            <Input
                                id="market"
                                name="Market"
                                placeholder="Market"
                                type="select"
                                value={this.state.marketValue}
                                onChange={(e) => { this.setState({ marketValue: e.target.value }) }}
                                onBlur={(e) => { this.checkMarket() }}

                            >
                                {this.listMarket()}
                            </Input>
                            {this.state.marketValueError !== '' ? <div  style={{color:'red'}}>{this.state.marketValueError}</div> : null}
                            <p style={{ marginTop: '10px' }} > Insert Key</p>
                            <Input
                                id="key"
                                name="Key"
                                placeholder="Key"
                                type="text"
                                value={this.state.key}
                                onChange={(e) => { this.setState({ key: e.target.value }) }}
                                onBlur={(e) => { this.checkKey() }}
                            />
                            {this.state.keyError !== '' ? <div  style={{color:'red'}}>{this.state.keyError}</div> : null}
                            <p style={{ marginTop: '10px' }}> Insert Secret</p>
                            <Input
                                id="secret"
                                name="Secret"
                                placeholder="Secret"
                                type="text"
                                value={this.state.secret}
                                onChange={(e) => { this.setState({ secret: e.target.value }) }}
                                onBlur={(e) => { this.checkSecret() }}
                            />
                            {this.state.secretError !== '' ? <div style={{color:'red'}}>{this.state.secretError}</div> : null}
                            {this.state.marketValue === 'gdax' ?
                                <div>
                                    <p style={{ marginTop: '10px' }}> Insert Passphrase</p>
                                    <Input
                                        id="passphrase"
                                        name="Passphrase"
                                        placeholder="Passphrase"
                                        type="text"
                                        value={this.state.passphrase}
                                        onBlur={(e) => { this.checkPassphrase() }}
                                        onChange={(e) => { this.setState({ passphrase: e.target.value }) }} />
                                    {this.state.passpgraseError !== '' ? <div  style={{color:'red'}}>{this.state.passpgraseError}</div> : null}
                                </div>
                                : null}

                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary " onClick={() => this.submit()}>Add Apikey</Button>
                            <Button color="danger " onClick={() => this.closeModal()}>Cancel</Button>
                        </ModalFooter>
                    </div>

                </Modal>
                <Header />
                <Container className="mt--7" fluid>
                    <Card>
                        <CardHeader>
                            <button className="btn btn-primary btn-sm" onClick={(e) => { this.openModal() }}>Add ApiKey</button>
                        </CardHeader>
                        <CardBody>
                            {this.state.loaded === true ?
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col" className="text-center">Exchange</th>
                                            <th scope="col" className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.apiKeys.length > 0 ? this.listaApiKeys() : null}
                                    </tbody>
                                </Table>
                                :
                                <center>
                                    <Spinner color="success" />
                                </center>}

                        </CardBody>
                    </Card>
                </Container>

            </div>
        )
    }

}

function mapStateToProps(state) {
    return { authenticated: state.auth.authenticated, user_credentials: state.auth.user_credentials };
}

export default compose(
    connect(mapStateToProps, actions)
)(withRouter(Config));
