//import Login from "components/Footers/AuthFooter";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

//import { reduxForm, Field } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from './../../actions';
//import { Link, NavLink } from "react-router-dom";

//import { DateTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";

// reactstrap components
import {
    //  Button,
    //  Card,
    //  CardHeader,
    //  CardBody,
    //  Form,
    //  Input,
    //  InputGroupAddon,
    //  InputGroupText,
    //  InputGroup,
    //  Row,
    //  Col,
    Table,
    //  FormGroup, 
    //  Label, 
    Spinner
    //  FormText
} from "reactstrap";

//var DatePicker = require("reactstrap-date-picker");

function differenceBetweenDates(date_future, date_now) {
    var d = Math.abs(date_future - date_now);                           // delta
    var r = {};                                                                // result
    var s = {                                                                  // structure
        year: 31536000,
        month: 2592000,
        week: 604800, // uncomment row to ignore
        day: 86400,   // feel free to add your own row
        hour: 3600,
        minute: 60,
        second: 1
    };

    Object.keys(s).forEach(function (key) {
        r[key] = Math.floor(d / s[key]);
        d -= r[key] * s[key];
    });

    // for example: {year:0,month:0,week:1,day:2,hour:34,minute:56,second:7}
    //console.log(r);
    var response = "";
    if (r.year > 0) { response = response + r.year + " years, " }
    if (r.month > 0) { response = response + r.month + " months, " }
    if (r.day > 0) { response = response + r.day + " days, " }
    if (r.hour > 0) { response = response + r.hour + " hours, " }
    if (r.minute > 0) { response = response + r.minute + " minutes" }
    return response
}


class DataImportTable extends Component {

    constructor(props) {
        super(props)
        this.state = {
            value: new Date().toISOString(),
            importSets: [],
            loaded: false
        }
    }

    handleChange(value, formattedValue) {
        this.setState({
            value: value, // ISO String, ex: "2016-11-19T12:00:00.000Z"
            formattedValue: formattedValue // Formatted String, ex: "11/19/2016"
        })
        //console.log("Noua Valoare " + this.state.value)
        //console.log("Noua valoare formatata " + this.state.formattedValue)
    }
    componentDidMount() {
        var myHeaders = new Headers();
        myHeaders.append("accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + this.props.authenticated);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        fetch( process.env.REACT_APP_URL_Gekko + '/api/gekko/' + this.props.user_credentials.user_id + '/' + this.props.user_credentials.bot_id + "/api/imports", requestOptions)
            .then(response => {
                if (response.status === 401) { console.log("test") }
                else { return response.text() }
            }
            )
            .then(response => JSON.parse(response))
            .then(result => {
                var finalArr = [];
                result.map(item => {
                    if (item.done != undefined) {
                        if (item.done == false) {
                            var duration = differenceBetweenDates( Date.parse(item.to)/1000, Date.parse(item.from)/1000)
                            var obj = {
                                from: item.from,
                                id: item.id,
                                to: item.to,
                                exchange: item.watch.exchange,
                                asset: item.watch.asset,
                                currency: item.watch.currency,
                                duration:duration
                            }
                            finalArr.push(obj)

                        }
                    }
                })
                this.setState({ importSets: finalArr, loaded: true })
            }
            )
            .catch(error => console.log('error', error));
    }


    listaImportSets() {
        var listimportSets = this.state.importSets.map((d, key) =>
            <tr key={key}>
                <td className="text-center">{d.exchange}</td>
                <td className="text-center">{d.currency}</td>
                <td className="text-center">{d.asset}</td>
                <td className="text-center">{d.from}</td>
                <td className="text-center">{d.to}</td>
                <td className="text-center">{d.duration}</td>

            </tr>
        );
        return listimportSets
    }
    render() {
        return (
            <div>
                {this.state.loaded === true ?
                    <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col" className="text-center">Exchange</th>
                                <th scope="col" className="text-center">Currency</th>
                                <th scope="col" className="text-center">Assets</th>
                                <th scope="col" className="text-center">From</th>
                                <th scope="col" className="text-center">To</th>
                                <th scope="col" className="text-center">Duration</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.importSets.length > 0 ? this.listaImportSets() : null}
                        </tbody>
                    </Table> :
                    <center>
                        <Spinner color="success" />
                    </center>
                }
            </div>
        )
    }
}


function mapStateToProps(state) {
    return { authenticated: state.auth.authenticated, user_credentials: state.auth.user_credentials };
}

export default compose(
    connect(mapStateToProps, actions)
)(withRouter(DataImportTable));
