//import Login from "components/Footers/AuthFooter";
import React, { Component } from 'react';
import { useHistory } from "react-router-dom";
import Header from '../Headers/Header';
//import BotsStrategy from '../Bots/BotsAdd/BotsConf/BotsStrategy'
import BacktestStrategy from './BacktestStrategy/BacktestStrategy'
import BacktestSimulationsConf from './BacktestSimulationsConf/BacktestSimulationsConf'
import BotsDatasets from "./BotsDataSets/BotsDataSets";
//import BotsSimulationsConf from "components/Bots/BotsAdd/BotsConf/BotsSimulationConf";
//import BotsParameters from "components/Bots/BotsAdd/BotsConf/BotsParameters"
import BacktestResultData from "./BacktestResultData/BacktestResultData";
//import BotsMarketGraphic from "../Bots/BotsGet/BotsMarketGraphic";
import BacktestMarketGraphic from "./BacktestMarketGraphic/BacktestMarketGraphic";
//import BotsTradesHistory from "components/Bots/BotsGet/BotsTradesHistory";
import BacktestTradesHistory from './BacktestTradesHistory/BacktestTradesHistory';
import axios from 'axios';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from './../../actions';
import BacktestView from './BacktestView/BacktestView';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    Container,
    Spinner
} from "reactstrap";

const BACKTEST_BOT = {
    watch: {
        "exchange": "",
        "currency": "",
        "asset": ""
    },
    paperTrader: {
        feeMaker: 0.25,
        feeTaker: 0.25,
        feeUsing: "maker",
        slippage: 0.05,
        simulationBalance: {
            asset: 1,
            currency: 100
        },
        reportRoundtrips: true,
        enabled: true
    },
    tradingAdvisor: {
        candleSize: 60,
        enabled: true,
        historySize: 10,
        method: "",
        warmup: 10
    },
    performanceAnalyzer: {
        enabled: true,
        riskFreeReturn: 2
    },
    backtest: {
        daterange: {
            from: '',
            to: ''
        }
    },
    backtestResultExporter: {
        enabled: true,
        writeToDisk: false,
        data: {
            stratUpdates: false,
            roundtrips: true,
            stratCandles: true,
            stratCandleProps: [
                "open"
            ],
            trades: true
        }
    },
}


class Backtest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "visible",
            showStartBacktest: false,
            backtest_bot: BACKTEST_BOT,
            backtest_strategy: '',
            paperTrader: {
                enabled: true,
                feeMaker:0.25,
                feeTaker :0.25,
                feeUsing:"maker",
                reportRoundtrips:true,
                simulationBalance :{ asset: 1, currency: 100 },
                    slippage: 0.05
            },
            ta4prof2: {
                BOT_SETTINGS: { First_Move: "buy" },
                DEBUG: { Level: 0 },
                ICHIMOKU:{ 
                    conversionPeriod: 11, 
                    basePeriod: 30, 
                    spanPeriod: 48, 
                    displacement: 30 
                },
                Indicators: {
                    MACD: true, 
                    PSAR: true, 
                    ICHIMOKU: true, 
                    TREND: true, 
                    STOPLOSS: true, 
                    TAKEPROFIT: false
                },
                LIMIT: {
                    loss: 10, 
                    gain: 10
                },
                MACD: {
                    short: 13, 
                    long: 25, 
                    signal: 6, 
                    thresholdDOWN: 0, 
                    thresholdUP: 0
                },
                MARKET_TREND: {
                    EMAlong: 130, 
                    EMAshort: 20, 
                    persistence: 3
                },
                PSAR: {
                    optInAcceleration: 0.25, 
                    optInMaximum: 0.02, 
                    optInStart: 0.02}
            },
            isFetching: false,
            isLoaded: false,
        };
    }
    changeTa4prof2 = (value, param) => {

        var properties = param.split('.');

        // Cloning the ta4prof2 object into result
        var result = JSON.parse(JSON.stringify(this.state.ta4prof2));

        // Accessing the nested property dynamically in the result object
        var currentObject = result;
        for (var i = 0; i < properties.length - 1; i++) {
            currentObject = currentObject[properties[i]];
        }

        // Updating the value in the result object
        var lastProperty = properties[properties.length - 1];
        currentObject[lastProperty] = value; // Assigning the new value

         this.setState({ ta4prof2:result })
    }

    confBacktestAddDataRange = (DataRange) => {
        //console.log("confBacktestAddDataRange updated from child")
        //console.log(DataRange)
        let BacktestDataRange = this.state.backtest_bot
        BacktestDataRange.watch = DataRange.watch
        BacktestDataRange.backtest = DataRange.backtest
        this.setState({ backtest_bot: BacktestDataRange })
    }

    confBacktestAddUpdateStrategy = (strategyName, strategyParams) => {
        //console.log("Strategy update from parent")
        //console.log(strategyName)
        ////console.log(strategyParams)
        let strategy = this.state.backtest_bot
        if (this.state.backtest_strategy !== '') {
            //let del = this.state.confBotAdd
            delete strategy[this.state.backtest_strategy]
            //console.log("Stergem fosta strategie")
            //console.log(this.state.backtest_strategy)

        }
        this.setState({ backtest_strategy: strategyName })
        strategy['tradingAdvisor']['method'] = strategyName
        strategy[strategyName] = strategyParams
        this.setState({ backtest_bot: strategy })
        //console.log(this.state.backtest_bot)
    }

    confBacktestAddUpdateStrategyParam = (tradingAdvisor) => {
        //console.log("Update trading Advisor from parent")
        let strategyParam = this.state.backtest_bot
        strategyParam['tradingAdvisor'] = tradingAdvisor
        this.setState({ backtest_strategy: strategyParam })
    }

    confBacktestAddUpdateSimulation = (SimulationParams) => {
        //console.log("Simulation parameters for backtest")
        this.setState({ paperTrader: SimulationParams })
        //console.log(this.state.backtest_bot)
    }

    startBot() {
        var config={
            ...this.state.backtest_bot,
            TA4prof2:{
                ...this.state.ta4prof2
            }
        }
        config.paperTrader={
            ...this.state.paperTrader
        }
        config.tradingAdvisor.method="TA4prof2"
         this.props.history.push("/admin/service/backtestView", { bot: config });
    }

    render() {

        return (
            <div>
                <Header />
                <Container className="mt--7" fluid>
                    <Row>
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Available Data Sets</h3>
                                        </div>
                                        <div className="col-md-6 text-right">
                                            <i className="fas fa-database text-success fa-2x"></i>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <BotsDatasets
                                        updateConf={this.confBacktestAddDataRange}
                                    />
                                </CardBody>

                            </Card>
                        </Col>
                    </Row>
                    <BacktestStrategy
                        updateConf={this.confBacktestAddUpdateStrategy}
                        updateConfStrategyParam={this.confBacktestAddUpdateStrategyParam}
                        changeTa4prof2={this.changeTa4prof2}
                        updateConfSimulation={this.confBacktestAddUpdateSimulation}
                    />




                    <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Backtest</h3>
                                        </div>
                                        <div className="col text-right">


                                            <Button
                                                className="btn btn-primary btn-sm"
                                                color="primary"
                                                size="sm"
                                                onClick={(e) => this.startBot()}

                                            > Start</Button>
                                        </div>
                                    </Row>
                                </CardHeader>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return { authenticated: state.auth.authenticated, user_credentials: state.auth.user_credentials };
}

export default compose(
    connect(mapStateToProps, actions)
)(Backtest);



