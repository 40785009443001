//import Login from "components/Footers/AuthFooter";
import React, { Component } from 'react';
//import { reduxForm, Field } from 'redux-form';
//import { compose } from 'redux';
//import { connect } from 'react-redux';
import moment from 'moment';
//import xtype from 'xtypejs'

import { Link } from "react-router-dom";
//import { Link } from 'react-router'

// reactstrap components
import {
  //  Button,
  //  Card,
  //  CardHeader,
  //  CardBody,
  //  FormGroup,
  //  Form,
  //  Input,
  //  InputGroupAddon,
  //  InputGroupText,
  //  InputGroup,
  //  Row,
  //  Col,
  Table,
  //  NavLink
} from "reactstrap";
//import BotsSimulationsConf from "../BotsAdd/BotsConf/BotsSimulationConf";


const runningBots = [
  {
    exchange: "poloniex",
    currency: "USDT",
    asset: "BTC",
    status: "running",
    duration: "1 week, 3 days, 19 hours, 17 minutes",
    strategy: "TA4prof2",
    pnl: "5172.917",
    bot_type: "	papertrader",
    trades: "4",
  },
  {
    exchange: "binance",
    currency: "BTC",
    asset: "ETH",
    status: "running",
    duration: "1 day, 22 hours, 43 minutes",
    strategy: "MACD",
    pnl: "0",
    bot_type: "	papertrader",
    trades: "0",
  },
]



class BotsList extends Component {

  constructor() {
    super();
    ////console.log("Component botslist")
    this.state = {
      activeBots: [],
      isLoaded: false,
      isFetching: false,
      updateData: false,
    }
  }



  componentDidMount() {
    console.log(this.props.bots.live)
    ////console.log("componentDidMount from Component botslist")
    ////console.log(this.props)
    this.updateData()
  }

  updateData = () => {
    //console.log('ttttttttttttttttttttttttttttttttttttttttest')
    let activeBots = []
    // //console.log(this.props.bots.live)
    for (var key in this.props.bots.live) {
      var value = this.props.bots.live[key];
      //console.log(key)
     
      if (value.config.type === 'paper trader' && value.active) {
        let bot = {}
        bot.id = value.id
        ////console.log(bot.id)
        bot.exchange = value.config.watch['exchange']
        ////console.log(bot.exchange)
        bot.currency = value.config.watch['currency']
        ////console.log(bot.currency)
        bot.asset = value.config.watch['asset']
        ////console.log(bot.asset)
        bot.status = "running"
        ////console.log(bot.status)

        //let start = moment(value.start)
        //let latestUpdate = moment(value.latestUpdate)
        ////console.log(bot.start.format('YYYY-MM-DD HH:mm'))
        ////console.log(bot.latestUpdate.format('YYYY-MM-DD HH:mm'))

        const diff = moment(value.lastUpdate).diff(moment(value.start));
        const diffDuration = moment.duration(diff);
        if (diffDuration.minutes()) { bot.duration = diffDuration.minutes() + " minutes" }
        if (diffDuration.hours()) { bot.duration = diffDuration.hours() + " hours " + bot.duration }
        if (diffDuration.days()) { bot.duration = diffDuration.days() + " days " + bot.duration }
        if (diffDuration.months()) { bot.duration = diffDuration.months() + " months " + bot.duration }
        if (diffDuration.years()) { bot.duration = diffDuration.years() + " years " + bot.duration }
        ////console.log( bot.duration)
        bot.strategy = value.config.tradingAdvisor['method']
        ////console.log(bot.strategy)
        //bot.pnl = value.events.latest.performanceReport['profit']
        ////console.log(xtype(bot.pnl))

        for (var k in value.events.latest.performanceReport) {
          if (k === "profit") {
            bot.pnl = parseFloat(value.events.latest.performanceReport[k]).toFixed(2)
          }
          if (k === "trades") {
            bot.trades = value.events.latest.performanceReport[k]
          }
        }
        if (typeof bot.trades == 'undefined') { bot.trades = 0 }
        if (typeof bot.pnl == 'undefined') { bot.pnl = 0 }
        ////console.log(bot.pnl)
        bot.bot_type = 'paper trading'
        //bot.trades = 2
        activeBots.push(bot)
      }
      if (value.config.type === 'traderbot' && value.active) {
        let bot = {}
        bot.id = value.id
        ////console.log(bot.id)
        bot.exchange = value.config.watch['exchange']
        ////console.log(bot.exchange)
        bot.currency = value.config.watch['currency']
        ////console.log(bot.currency)
        bot.asset = value.config.watch['asset']
        ////console.log(bot.asset)
        bot.status = "running"
        ////console.log(bot.status)

        //let start = moment(value.start)
        //let latestUpdate = moment(value.latestUpdate)
        ////console.log(bot.start.format('YYYY-MM-DD HH:mm'))
        ////console.log(bot.latestUpdate.format('YYYY-MM-DD HH:mm'))

        const diff = moment(value.lastUpdate).diff(moment(value.start));
        const diffDuration = moment.duration(diff);
        if (diffDuration.minutes()) { bot.duration = diffDuration.minutes() + " minutes" }
        if (diffDuration.hours()) { bot.duration = diffDuration.hours() + " hours " + bot.duration }
        if (diffDuration.days()) { bot.duration = diffDuration.days() + " days " + bot.duration }
        if (diffDuration.months()) { bot.duration = diffDuration.months() + " months " + bot.duration }
        if (diffDuration.years()) { bot.duration = diffDuration.years() + " years " + bot.duration }
        ////console.log( bot.duration)
        bot.strategy = value.config.tradingAdvisor['method']
        ////console.log(bot.strategy)
        //bot.pnl = value.events.latest.performanceReport['profit']
        ////console.log(xtype(bot.pnl))

        for (var k in value.events.latest.performanceReport) {
          if (k === "profit") {
            bot.pnl = parseFloat(value.events.latest.performanceReport[k]).toFixed(2)
          }
          if (k === "trades") {
            bot.trades = value.events.latest.performanceReport[k]
          }
        }
        if (typeof bot.trades == 'undefined') { bot.trades = 0 }
        if (typeof bot.pnl == 'undefined') { bot.pnl = 0 }
        ////console.log(bot.pnl)
        bot.bot_type = 'tradebot'
        //bot.trades = 2
        activeBots.push(bot)
      }
      
    
    }
    this.setState({ activeBots: activeBots, isLoaded: true })
    ////console.log("apelam functia getBotsSummary")
    ////console.log("Child is Fetching " + this.props.isFetching)
    ////console.log("Bots in child: ")
    // //console.log(this.props.bots.live)

  }

  listBots = runningBots.map((d) =>
    <tr>
      <th scope="row">{d.exchange}</th>
      <td>{d.currency}</td>
      <td>{d.asset}</td>
      <td>{d.status}</td>
      <td>{d.duration}</td>
      <td>{d.strategy}</td>
      <td>{d.pnl}</td>
      <td>{d.bot_type}</td>
      <td>{d.trades}</td>
    </tr>

  );

  listRunningBots = () => {
    ////console.log("listRunningBots")
    // //console.log(this.state.activeBots)

    return (
      this.state.activeBots.map((d) =>
        /*<Link 
          to={`/admin/service/botsview/${d.id}`} 
        isActive>*/


        <tr id={d.id} key={d.id}>
          {console.log(d)}
          <th scope="row">
            <Link
              to={{
                pathname: "/admin/service/botsview",
                bot_id: d.id,
                bot_type: d.bot_type
              }}
              >

              {d.exchange}

            </Link>
          </th>
          <td>{d.currency}</td>
          <td>{d.asset}</td>
          <td>{d.status}</td>
          <td>{d.duration}</td>
          <td>{d.strategy}</td>
          <td>{d.pnl}</td>
          <td>{d.bot_type}</td>
          <td>{d.trades}</td>
        </tr>

      )
    )
  }

  render() {
    return (
      <div>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">Exchange</th>
              <th scope="col">Currency</th>
              <th scope="col">Asset</th>
              <th scope="col">Status</th>
              <th scope="col">Duration</th>
              <th scope="col">Strategy</th>
              <th scope="col">PNL</th>
              <th scope="col">Type</th>
              <th scope="col">Trades</th>
            </tr>
          </thead>
          <tbody>
            {this.state.isLoaded &&
              this.listRunningBots()
            }
          </tbody>
        </Table>
      </div>
    )
  }
}

export default BotsList;


