//import Login from "components/Footers/AuthFooter";
import React, { Component } from 'react';
//import { useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";
//import { reduxForm, Field } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
//import { Link, NavLink } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';

//import BotsSummary from './BotsSummary'
//import BotsAdd from './BotsAdd';
import Header from "components/Headers/Header";
import MarketWatcher from "./BotsSummary/MarketWatcher";
import BotsList from "./BotsSummary/BotsList";
//import BotsAddSummary from "./BotsSummary/BotsAddSummary"
import BotsExchange from "./BotsAdd/BotsConf/BotsExchange";
//import BotsType from "./BotsAdd/BotsConf/BotsType";
import BotsStrategy from "./BotsAdd/BotsConf/BotsStrategy";
//import BotsParameters from "./BotsAdd/BotsConf/BotsParameters";
import BotsSimulationsConf from "./BotsAdd/BotsConf/BotsSimulationConf";
//import BotsGet from "components/Bots/BotsGet";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    //  FormGroup,
    //  Form,
    //  Input,
    //  InputGroupAddon,
    //  InputGroupText,
    //  InputGroup,
    Row,
    Col,
    Container,
} from "reactstrap";


const PAPER_TRADER = {
    watch: {
        "exchange": "",
        "currency": "",
        "asset": ""
    },
    market: {
        type: "leech",
        from: '',
    },
    valid: true,
    type: "paper trader",
    mode: "realtime",
    tradingAdvisor: {
        candleSize: 60,
        enabled: true,
        historySize: 10,
        method: "",
        warmup: 10
    },
    candleWriter: {
        adapter: "sqlite",
        enabled: true,
    },
    performanceAnalyzer: {
        enabled: true,
        riskFreeReturn: 2
    },
    paperTrader: {
        enabled: true,
        feeMaker: 0.25,
        feeTaker: 0.25,
        feeUsing: "maker",
        reportRoundtrips: true,
        simulationBalance: {
            asset: 100,
            currency: 1,

        }
    }
}

const TRADER_BOT = {
    watch: {
        "exchange": "",
        "currency": "",
        "asset": ""
    },
    market: {
        type: "leech",
        from: '',
    },
    valid: true,
    type: "paper trader",
    mode: "realtime",
    tradingAdvisor: {
        candleSize: 60,
        enabled: true,
        historySize: 10,
        method: "",
        warmup: 10
    },
    candleWriter: {
        adapter: "sqlite",
        enabled: true,
    },
    performanceAnalyzer: {
        enabled: true,
        riskFreeReturn: 2
    },
}

class Bots extends Component {

    constructor(props) {
        ////console.log("Constructor de Bots")
        super(props);

        this.state = {
            name: "visible",
            showMarketWatcher: true,
            showBotsList: true,
            showBotsAddButton: true,
            showBotsExchange: false,
            showBotsStrategy: false,
            showBotsSimulationsConf: false,
            showBotsStartButton: false,
            bots: '',
            isFetching: false,
            isLoaded: false,
            updateData: false,
            botAddType: "papertrader",
            confBotAdd: PAPER_TRADER,
            confBotAddStrategy: '',
        };
        this.hideComponentPaperTrader = this.hideComponentPaperTrader.bind(this);
        this.hideComponentMarketWatcher = this.hideComponentMarketWatcher.bind(this);
        this.hideComponentTraderBot = this.hideComponentTraderBot.bind(this);
        this.hideComponentBotsSummary = this.hideComponentBotsSummary.bind(this);
     
        //this.fetchBotsData();


        //fetchBotsData();
        ////console.log("Valoarea Bots in Constructor")
        ////console.log(this.state.bots);

    }

  
    confBotAddUpdateStrategy = (strategyName, strategyParams) => {
        //console.log("Strategy update from parent")
        //console.log(strategyName)
        ////console.log(strategyParams)
        let strategy = this.state.confBotAdd
        if (this.state.confBotAddStrategy !== '') {
            //let del = this.state.confBotAdd
            delete strategy[this.state.confBotAddStrategy]
            //console.log("Stergem ")
            //console.log(this.state.confBotAddStrategy)

        }
        this.setState({ confBotAddStrategy: strategyName })
        strategy['tradingAdvisor']['method'] = strategyName
        strategy[strategyName] = strategyParams
        this.setState({ confBotAdd: strategy })
        //console.log(this.state.confBotAdd)
    }

    confBotAddUpdateStrategyParam = (tradingAdvisor) => {
        //console.log("Update trading Advisor from parent")
        let strategyParam = this.state.confBotAdd
        strategyParam['tradingAdvisor'] = tradingAdvisor
        this.setState({ confBotAdd: strategyParam })
    }

   


    parseJwt(token) {
        if (!token) { return; }
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    }

    componentDidMount() {

        //console.log("componentDidMount for index bots")
        ////console.log(moment().format("yyyy-MM-DDTHH:mm:ss"))
        //slet dateStart = moment().format("yyyy-MM-DDTHH:mm:ss") + "Z"
        ////console.log(dateStart)

        let jsondata = this.state.confBotAdd
        //console.log(this.state.confBotAdd)
        let dateStart = moment().format("yyyy-MM-DDTHH:mm:ss") + "Z"
        jsondata.market.from = dateStart
        //console.log(jsondata)

        if (this.state.bots === '' && !this.state.updateData) {
            //console.log("Bots e gol! Iau datele acum!")
            this.setState({ isLoaded: false, isFetching: true })

            this.fetchBotsData();

            this.setState({ isLoaded: true, isFetching: false })
        }
    }

    async fetchBotsData() {
        //console.log("FetchBotsData nou")

        this.setState({ isFetching: true })
        const response = await axios.get(
            process.env.REACT_APP_URL_Gekko + '/api/gekko/' + this.props.user_credentials.user_id + '/' + this.props.user_credentials.bot_id + '/api/gekkos', {
            headers: {
                "Authorization": " Bearer " + this.props.authenticated,
                "content-type": "application/json"
            }
        }).catch(err => {
            if (err.response.status === 401) { console.log("test") }
        })
        if (response===undefined ) { console.log('error') }
        else {
          
            this.setState({ bots: response.data, isFetching: false })
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.state.updateData === true) {
            //console.log("componentDidUpdate")
            this.setState({ isLoaded: false, isFetching: true })
            //this.setState({bots: {}})
            this.fetchBotsData();
            this.setState({ isLoaded: true, isFetching: false })
            this.setState({ updateData: false })
        }
    }
    confBotAddUpdateSimulation = (SimulationParams) => {
        //console.log("Simulation parameters update from parent")
        let confSimulation = this.state.confBotAdd
        confSimulation["paperTrader"] = SimulationParams
        this.setState({ confBotAdd: confSimulation })
    }

  
    goToBotsAdd() {
        this.props.history.push('/admin/service/botAdd');
    }

    hideComponentPaperTrader() {
        //console.log("hideCOmponentPaperTrader")
        this.setState({ showMarketWatcher: false });
        this.setState({ showBotsList: false });
        this.setState({ showBotsAddButton: false });
        this.setState({ showBotsExchange: true });
        this.setState({ showBotsStrategy: true });
        this.setState({ showBotsSimulationsConf: true });
        this.setState({ showBotsStartButton: true });
        this.setState({ botAddType: "papertrader" });

        let type = this.state.confBotAdd
        type["type"] = "paper trader"
        this.setState({ confBotAdd: type })
        //console.log(this.state.confBotAdd)
    }

    hideComponentMarketWatcher() {
        this.setState({ showMarketWatcher: false });
        this.setState({ showBotsList: false });
        this.setState({ showBotsAddButton: false });
        this.setState({ showBotsExchange: true });
        this.setState({ showBotsStrategy: false });
        this.setState({ showBotsSimulationsConf: false });
        this.setState({ showBotsStartButton: true });
        this.setState({ botAddType: "marketwatcher" });

        let type = this.state.confBotAdd
        type["type"] = "market watcher"
        this.setState({ confBotAdd: type })
        //console.log(this.state.confBotAdd)
    }

    hideComponentTraderBot() {
        //console.log("hideComponentTraderBot")
        this.setState({ showMarketWatcher: false });
        this.setState({ showBotsList: false });
        this.setState({ showBotsAddButton: false });
        this.setState({ showBotsExchange: true });
        this.setState({ showBotsStrategy: true });
        this.setState({ showBotsSimulationsConf: false });
        this.setState({ showBotsStartButton: true });
        this.setState({ botAddType: "traderbot" });

        let type = this.state.confBotAdd
        type["type"] = "trader bot"
        this.setState({ confBotAdd: type })
        //console.log(this.state.confBotAdd)
    }

    hideComponentBotsSummary() {
        this.setState({ showMarketWatcher: true });
        this.setState({ showBotsList: true });
        this.setState({ showBotsAddButton: true });
        this.setState({ showBotsExchange: false });
        this.setState({ showBotsStrategy: false });
        this.setState({ showBotsSimulationsConf: false });
        this.setState({ showBotsStartButton: false });
        this.setState({ botAddType: "papertrader" });

        let type = this.state.confBotAdd
        type["type"] = "paper trader"
        this.setState({ confBotAdd: type })
        //console.log(this.state.confBotAdd)
    }


    render() {

        const { showMarketWatcher,
            showBotsList,
            showBotsAddButton,
            showBotsExchange,
            showBotsStrategy,
            showBotsSimulationsConf,
            showBotsStartButton,
            bots,
            isLoaded,
            isFetching
        } = this.state;

        return (
            <div>

                <Header />
                <Container className="mt--7" fluid>
                    {showMarketWatcher && bots && isLoaded && !isFetching ? <Row>
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Market Watchers</h3>
                                        </div>
                                        <div>
                                            <i className="fas fa-microscope text-success fa-2x"></i>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <MarketWatcher {...this.props}
                                        watchers={this.state.bots}
                                        isFetching={this.state.isFetching}
                                        updateData={this.state.updateData} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row> : null}

                    {showBotsList && bots ? <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Trading Bots</h3>
                                        </div>
                                        <div>
                                            <i className="fas fa-robot text-success fa-2x"></i>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody><BotsList {...this.props}
                                    bots={this.state.bots}
                                    isFetching={this.state.isFetching} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row> : null}

                    {showBotsAddButton && <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">New trading bot / paper tradingbot / market watcher</h3>
                                        </div>
                                        <div className="col text-right">
                                            <Button
                                                color="primary"
                                               
                                                onClick={() => this.goToBotsAdd()}
                                                size="sm"
                                            >
                                                New
                                            </Button>
                                        </div>
                                    </Row>
                                </CardHeader>

                            </Card>
                        </Col>
                    </Row>}

                    {showBotsExchange && <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="8">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Markets</h3>
                                        </div>
                                        <div>
                                            <i className="fas fa-handshake text-success fa-2x"></i>
                                        </div>
                                        {/*<div className="col">
                                    <i className="fas fa-btc text-success fa-2x"></i>
                                </div>*/}
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <BotsExchange updateConf={this.confBotAddUpdateWatch} />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col className="mb-10 mb-xl-0" xl="4">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Type</h3>
                                        </div>
                                        <div>
                                            <i className="fas fa-heartbeat text-success fa-2x"></i>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    {/*<BotsType />*/}
                                    <Container>
                                        <Row className="mt-1">
                                            <Col sm={{ size: 8, order: 1, offset: 1 }}>
                                                <input
                                                    type="radio"
                                                    value="papertrader"
                                                    name="type"
                                                    size="sm"
                                                    checked={this.state.botAddType === "papertrader"}
                                                    onChange={() => this.hideComponentPaperTrader()}
                                                /> Paper Trader
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col sm={{ size: 8, order: 1, offset: 1 }}>
                                                <input
                                                    type="radio"
                                                    value="marketwatcher"
                                                    name="type"
                                                    size="sm"
                                                    checked={this.state.botAddType === "marketwatcher"}
                                                    onChange={() => this.hideComponentMarketWatcher()}
                                                /> Market Watcher
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col sm={{ size: 8, order: 1, offset: 1 }}>
                                                <input
                                                    type="radio"
                                                    value="traderbot"
                                                    name="type"
                                                    size="sm"
                                                    checked={this.state.botAddType === "traderbot"}
                                                    onChange={this.hideComponentTraderBot}
                                                /> Trader Bot
                                            </Col>
                                        </Row>
                                    </Container>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>}

                    {showBotsStrategy && <BotsStrategy
                        updateConf={this.confBotAddUpdateStrategy}
                        updateConfStrategyParam={this.confBotAddUpdateStrategyParam} />}



                    {showBotsSimulationsConf && <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="6">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Simulation Configuration</h3>
                                        </div>
                                        <div>
                                            <i className="fas fa-screwdriver text-success fa-2x"></i>
                                        </div>
                                        {/*<div className="col">
                                    <i className="fas fa-btc text-success fa-2x"></i>
                                </div>*/}
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <BotsSimulationsConf
                                        updateConf={this.confBotAddUpdateSimulation}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>}

                    {showBotsStartButton && <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Start trading bot / paper tradingbot / market watcher</h3>
                                        </div>
                                        <div className="col text-right">
                                            <Button
                                                color="primary"
                                              
                                                onClick={() => this.goToBotsAdd()}
                                                size="sm"
                                            >
                                                Start
                                            </Button>
                                        </div>
                                    </Row>
                                </CardHeader>
                            </Card>
                        </Col>
                    </Row>}

                </Container>



            </div>
        )
    }
}

function mapStateToProps(state) {
    return { authenticated: state.auth.authenticated, user_credentials: state.auth.user_credentials };
}

export default compose(
    connect(mapStateToProps, actions)
)(withRouter(Bots));


