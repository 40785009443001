//import Login from "components/Footers/AuthFooter";
import React, { Component } from 'react';
//import { reduxForm, Field } from 'redux-form';
//import { compose } from 'redux';
//import { connect } from 'react-redux';

import { Link } from "react-router-dom";
import moment from 'moment';


// reactstrap components
import {
//  Button,
//  Card,
//  CardHeader,
//  CardBody,
//  FormGroup,
//  Form,
//  Input,
//  InputGroupAddon,
//  InputGroupText,
//  InputGroup,
//  Row,
//  Col,
  Table,
} from "reactstrap";


const watchers = [
    {
        exchange: "poloniex",
        currency: "USDT",
        asset: "BTC",
        status: "running",
        started_at: "2021-02-12 20:44",
        last_update: "2021-02-23 16:01",
        duration: "1 week, 3 days, 19 hours, 17 minutes",
    },
    {
        exchange: "binance",
        currency: "BTC",
        asset:"ETH",
        status: "running",
        started_at: "2021-02-21 17:21",
        last_update: "2021-02-23 16:04",
        duration: "1 day, 22 hours, 43 minutes",
    },
]

class MarketWatcher extends Component {

  constructor() {
    super();
    this.state = {
      activeWatchers: [],
      isLoaded: false,
      isFetching: false,
      updateData: false
    }
  }

  
  componentDidMount () {
    ////console.log("componentDidMount from Component botslist")
    ////console.log(this.props)
    this.updateData()

  }

  
  componentDidUpdate () {
    
   // //console.log("componentDidUpdate from Market Watcher")
    ////console.log("this.props.updateData" + this.props.updateData)
    ////console.log("nr de watcheri activi deja setati in componenta" + this.state.activeWatchers.length)
    
    function count(obj) {
      var count=0;
      for(var prop in obj) {
         if (obj.hasOwnProperty(prop) && obj[prop].config.type === 'market watcher') {
            ++count;
         }
      }
      return count;
    }
    //console.log("nr de watcheri activi trimisi de parinte " + count(this.props.watchers.live))
    if (this.state.activeWatchers.length !== count(this.props.watchers.live)) {
      this.setState({isFetching: true, isLoaded: false})
      this.updateData()
      this.setState({isLoaded: true, isFetching: false})
    }
    
  }
  
  updateData = () => {
    let activeWatchers = []
    for (var key in this.props.watchers.live) {
      var value = this.props.watchers.live[key];
      
      if (value.config.type === 'market watcher' && value.active) {
        let watcher = {}        
        watcher.id = value.id
        ////console.log(watcher.id)
        watcher.exchange = value.config.watch['exchange']
        ////console.log(watcher.exchange)
        watcher.currency = value.config.watch['currency']   
        ////console.log(watcher.currency)
        watcher.asset = value.config.watch['asset']
        ////console.log(watcher.asset)
        watcher.status = "running"
        ////console.log(watcher.status)
        
        //let start = moment(value.start)
        //let latestUpdate = moment(value.latestUpdate)

        ////console.log(bot.start.format('YYYY-MM-DD HH:mm'))
        ////console.log(bot.latestUpdate.format('YYYY-MM-DD HH:mm'))
        watcher.started_at = moment(value.start).format('YYYY-MM-DD HH:mm')
        watcher.last_update = moment(value.latestUpdate).format('YYYY-MM-DD HH:mm')

        const diff = moment(value.lastUpdate).diff(moment(value.start));
        const diffDuration = moment.duration(diff);
        if (diffDuration.minutes()) { watcher.duration = diffDuration.minutes() + " minutes"}
        if (diffDuration.hours()) { watcher.duration = diffDuration.hours() + " hours " + watcher.duration}
        if (diffDuration.days()) { watcher.duration = diffDuration.days() + " days " + watcher.duration}
        if (diffDuration.months()) {watcher.duration = diffDuration.months() + " months " + watcher.duration}
        if (diffDuration.years()) {watcher.duration = diffDuration.years() + " years " + watcher.duration}
        ////console.log( watcher.duration)
        
        activeWatchers.push(watcher)
      }
      ////console.log(activeBots)
      this.setState({activeWatchers: activeWatchers, isLoaded: true})
    }
    ////console.log(this.props.watchers.live)
  }

    listWatchers = watchers.map((d,key) => 
            <tr key={key}>
                <th scope="row">{d.exchange}</th>
                <td>{d.currency}</td>
                <td>{d.asset}</td>
                <td>{d.status}</td>
                <td>{d.started_at}</td>
                <td>{d.last_update}</td>
                <td>{d.duration}</td>
            </tr>
            );
    
    listRunningWatchers = () => {
      return(
        this.state.activeWatchers.map((d) => 
        
            <tr>
                <th scope="row">
                <Link 
                  to = { {
                    pathname: "/admin/service/botsview",
                    bot_id: d.id,
                    bot_type: "market watcher"} } 
                  >
                
                  {d.exchange}
                </Link>
                </th>
                <td>{d.currency}</td>
                <td>{d.asset}</td>
                <td>{d.status}</td>
                <td>{d.started_at}</td>
                <td>{d.last_update}</td>
                <td>{d.duration}</td>
            </tr>
          
        )
      )
    }

    render () {
        return(
            <div>
            <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Exchange</th>
                <th scope="col">Currency</th>
                <th scope="col">Asset</th>
                <th scope="col">Status</th>
                <th scope="col">Started at</th>
                <th scope="col">Last Update</th>
                <th scope="col">Duration</th>
              </tr>
            </thead>
            <tbody>
              {this.state.isLoaded && this.listRunningWatchers()}
            </tbody>
            </Table>
            </div>
        )
    }
};

export default MarketWatcher;