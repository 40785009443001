//import Login from "components/Footers/AuthFooter";
import React, { Component } from 'react';
//import { useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";
//import { reduxForm, Field } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
//import { Link, NavLink } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';

//import BotsSummary from './BotsSummary'
//import BotsAdd from './BotsAdd';
import Header from "components/Headers/Header";
import MarketWatcher from "./BotsSummary/MarketWatcher";
import BotsList from "./BotsSummary/BotsList";
//import BotsAddSummary from "./BotsSummary/BotsAddSummary"
import BotsExchange from "./BotsAdd/BotsConf/BotsExchange";
//import BotsType from "./BotsAdd/BotsConf/BotsType";
import BotsStrategy from "./BotsAdd/BotsConf/BotsStrategy";
//import BotsParameters from "./BotsAdd/BotsConf/BotsParameters";
import BotsSimulationsConf from "./BotsAdd/BotsConf/BotsSimulationConf";
//import BotsGet from "components/Bots/BotsGet";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    //  FormGroup,
    //  Form,
    //  Input,
    //  InputGroupAddon,
    //  InputGroupText,
    //  InputGroup,
    Row,
    Col,
    Container,
} from "reactstrap";
//import { render } from "node-sass";
const MARKET_WATCHER = {
    watch: {
        "exchange": "",
        "currency": "",
        "asset": ""
    },
    candleWriter: {
        "enabled": true,
        "adapter": "sqllite"
    },
    type: "market watcher",
    mode: "realtime"
}



const PAPER_TRADER = {
    watch: {
        "exchange": "",
        "currency": "",
        "asset": ""
    },
    market: {
        type: "leech",
        from: '',
    },
    valid: true,
    type: "paper trader",
    mode: "realtime",
    tradingAdvisor: {
        candleSize: 60,
        enabled: true,
        historySize: 10,
        method: "TA4prof2",
        warmup: 10
    },
    candleWriter: {
        adapter: "sqlite",
        enabled: true,
    },
    performanceAnalyzer: {
        enabled: true,
        riskFreeReturn: 2
    },
    paperTrader: {
        enabled: true,
        feeMaker: 0.25,
        feeTaker: 0.25,
        feeUsing: "maker",
        reportRoundtrips: true,
        simulationBalance: {
            asset: 100,
            currency: 1,

        }
    }
}

class BotsAdd extends Component {
    constructor(props) {
        ////console.log("Constructor de Bots")
        super(props);

        this.state = {
            name: "visible",
            showMarketWatcher: true,
            showBotsList: true,
            showBotsAddButton: true,
            showBotsExchange: true,
            showBotsStrategy: true,
            showBotsSimulationsConf: true,
            showBotsStartButton: true,
            tradingAdvisor: {
                candleSize: 60,
                enabled: true,
                historySize: 10,
                method: "TA4prof2",
                warmup: 10
            },
            bots: '',
            isFetching: false,
            isLoaded: false,
            updateData: false,
            botAddType: "papertrader",
            confBotAdd: PAPER_TRADER,
            paperTrader: {
                enabled: true,
                feeMaker:0.25,
                feeTaker :0.25,
                feeUsing:"maker",
                reportRoundtrips:true,
                simulationBalance :{ asset: 1, currency: 100 },
                    slippage: 0.05
            },
            confBotAddStrategy: 'TA4prof2',
            ta4prof2: {
                BOT_SETTINGS: { First_Move: "buy" },
                DEBUG: { Level: 0 },
                ICHIMOKU: {
                    conversionPeriod: 11,
                    basePeriod: 30,
                    spanPeriod: 48,
                    displacement: 30
                },
                Indicators: {
                    MACD: true,
                    PSAR: true,
                    ICHIMOKU: true,
                    TREND: true,
                    STOPLOSS: true,
                    TAKEPROFIT: false
                },
                LIMIT: {
                    loss: 10,
                    gain: 10
                },
                MACD: {
                    short: 13,
                    long: 25,
                    signal: 6,
                    thresholdDOWN: 0,
                    thresholdUP: 0
                },
                MARKET_TREND: {
                    EMAlong: 130,
                    EMAshort: 20,
                    persistence: 3
                },
                PSAR: {
                    optInAcceleration: 0.25,
                    optInMaximum: 0.02,
                    optInStart: 0.02
                }
            }
        };
        this.hideComponentPaperTrader = this.hideComponentPaperTrader.bind(this);
        this.hideComponentPaperTrader = this.hideComponentPaperTrader.bind(this);
        this.hideComponentMarketWatcher = this.hideComponentMarketWatcher.bind(this);
        this.hideComponentTraderBot = this.hideComponentTraderBot.bind(this);
        this.hideComponentBotsSummary = this.hideComponentBotsSummary.bind(this);

    }
    changeTa4prof2 = (value, param) => {

        var properties = param.split('.');

        // Cloning the ta4prof2 object into result
        var result = JSON.parse(JSON.stringify(this.state.ta4prof2));

        // Accessing the nested property dynamically in the result object
        var currentObject = result;
        for (var i = 0; i < properties.length - 1; i++) {
            currentObject = currentObject[properties[i]];
        }

        // Updating the value in the result object
        var lastProperty = properties[properties.length - 1];
        currentObject[lastProperty] = value; // Assigning the new value

        this.setState({ ta4prof2: result })
    }

    hideComponentPaperTrader() {
        //console.log("hideCOmponentPaperTrader")
        this.setState({ showMarketWatcher: false });
        this.setState({ showBotsList: false });
        this.setState({ showBotsAddButton: false });
        this.setState({ showBotsExchange: true });
        this.setState({ showBotsStrategy: true });
        this.setState({ showBotsSimulationsConf: true });
        this.setState({ showBotsStartButton: true });
        this.setState({ botAddType: "papertrader" });

        let type = this.state.confBotAdd
        type["type"] = "paper trader"
        this.setState({ confBotAdd: type })
        //console.log(this.state.confBotAdd)
    }

    hideComponentMarketWatcher() {
        this.setState({ showMarketWatcher: false });
        this.setState({ showBotsList: false });
        this.setState({ showBotsAddButton: false });
        this.setState({ showBotsExchange: true });
        this.setState({ showBotsStrategy: false });
        this.setState({ showBotsSimulationsConf: false });
        this.setState({ showBotsStartButton: true });
        this.setState({ botAddType: "marketwatcher" });

        let type = this.state.confBotAdd
        type["type"] = "market watcher"
        this.setState({ confBotAdd: type })
        //console.log(this.state.confBotAdd)
    }
    confBacktestAddUpdateSimulation = (SimulationParams) => {
        //console.log("Simulation parameters for backtest")
       this.setState({ paperTrader: SimulationParams })
        //console.log(this.state.backtest_bot)
    }

    hideComponentTraderBot() {


        this.setState({ showBotsList: false });
        this.setState({ showBotsAddButton: false });
        this.setState({ showBotsExchange: true });
        this.setState({ showBotsStrategy: true });
        this.setState({ showBotsSimulationsConf: false });
        this.setState({ showBotsStartButton: true });
        this.setState({ botAddType: "traderbot" });

        let type = this.state.confBotAdd
        type["type"] = "trader bot"
        this.setState({ confBotAdd: type })
        //console.log(this.state.confBotAdd)
    }

    confBotAddUpdateStrategyParam = (tradingAdvisor) => {
        //console.log("Update trading Advisor from parent")

        this.setState({tradingAdvisor:tradingAdvisor})
    }

    hideComponentBotsSummary() {
        this.setState({ showMarketWatcher: true });
        this.setState({ showBotsList: true });
        this.setState({ showBotsAddButton: true });
        this.setState({ showBotsExchange: false });
        this.setState({ showBotsStrategy: false });
        this.setState({ showBotsSimulationsConf: false });
        this.setState({ showBotsStartButton: false });
        this.setState({ botAddType: "papertrader" });

        let type = this.state.confBotAdd
        type["type"] = "paper trader"
        this.setState({ confBotAdd: type })
        //console.log(this.state.confBotAdd)
    }
    confBotAddUpdateWatch = (value) => {
        //console.log("Watch from parent")
        //console.log(value)
        let watch = this.state.confBotAdd
        watch["watch"] = value
        this.setState({ confBotAdd: watch })
        //console.log(this.state.confBotAdd)
    }
    


    goToBotsAdd() {
        const startBot = async () => {


            const headers = {
                "Authorization": "Bearer " + this.props.authenticated,
                "content-type": "application/json",
                "Cache-Control": "no-cache,no-store,must-revalidate,max-age=-1,private",
            };

            //console.log("Start first MW")

            let jsondata_bot = this.state.confBotAdd

            //console.log("Bot type: " + jsondata_bot.type)


            let jsondata_mw = MARKET_WATCHER
            jsondata_mw['watch'] = jsondata_bot['watch']
            //console.log(jsondata_bot)
            if ((jsondata_bot.type === 'paper trader') || (jsondata_bot.type === 'trader bot')) {
                var dateStart = moment().utc().subtract(50, 'minutes').format("yyyy-MM-DDTHH:mm:ss") + "Z"
                //DEBUG
                if (jsondata_bot.type === "trader bot") {
                    jsondata_bot.type = "traderbot"
                }
                //DEBUG
                jsondata_bot.market.from = dateStart

                //DEBUG
                //console.log("Paper trader")
                //console.log(jsondata_bot)
                //console.log("Paper trader")
                //DEBUG
        
                var final_obj={
                    ...jsondata_bot,
                    TA4prof2:this.state.ta4prof2,
                    paperTrader:this.state.paperTrader,
                    tradingAdvisor:this.state.tradingAdvisor
                }
               
            
                axios.post(
                    process.env.REACT_APP_URL_Gekko + '/api/gekko/' + this.props.user_credentials.user_id + '/' + this.props.user_credentials.bot_id + '/api/startGekko',
                    final_obj,
                    { headers: headers }
                ).then(res => {
                    if (res.status === 401) { console.log("test") }
                    //console.log("Answer from paper trader")
                    //console.log(res.data)
                    //console.log("Answer from paper trader")
                }
                ).catch(err => {
                    if (err.response.status === 401) { console.log("test") }
                })
            }
            else {
              
                await axios.post(
                    process.env.REACT_APP_URL_Gekko + '/api/gekko/' + this.props.user_credentials.user_id + '/' + this.props.user_credentials.bot_id + '/api/startGekko',
                    jsondata_mw,
                    { headers: headers }
                ).then(res => {
                    //DEBUG

                    if (res.status === 401) { console.log("test") }

                    //console.log("Answer from market watcher")
                    //console.log(res.data)
                    //console.log("Answer from market watcher")
                    //DEBUG


                }
                ).catch(err => {
                    //console.log(err)

                })
            }

        }


        this.setState({ isLoaded: false, isFetching: true })

        startBot()

        this.hideComponentBotsSummary()
        this.setState({ isLoaded: true, isFetching: false })
        //console.log("reincarcam pagina de bots")

        this.setState({ updateData: true })
        this.props.history.push('/admin/service/bots');
        //window.location.href ='/admin/service/bots';
        return false;
    }



    render() {

        return (
            <div>
                <Header />
                <Container className="mt--7" fluid>
                    {this.state.showBotsExchange && <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="8">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Markets</h3>
                                        </div>
                                        <div>
                                            <i className="fas fa-handshake text-success fa-2x"></i>
                                        </div>
                                        {/*<div className="col">
                        <i className="fas fa-btc text-success fa-2x"></i>
                    </div>*/}
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <BotsExchange updateConf={this.confBotAddUpdateWatch} />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col className="mb-10 mb-xl-0" xl="4">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Type</h3>
                                        </div>
                                        <div>
                                            <i className="fas fa-heartbeat text-success fa-2x"></i>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    {/*<BotsType />*/}
                                    <Container>
                                        <Row className="mt-1">
                                            <Col sm={{ size: 8, order: 1, offset: 1 }}>
                                                <input
                                                    type="radio"
                                                    value="papertrader"
                                                    name="type"
                                                    size="sm"
                                                    checked={this.state.botAddType === "papertrader"}
                                                    onChange={() => this.hideComponentPaperTrader()}
                                                /> Paper Trader
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col sm={{ size: 8, order: 1, offset: 1 }}>
                                                <input
                                                    type="radio"
                                                    value="marketwatcher"
                                                    name="type"
                                                    size="sm"
                                                    checked={this.state.botAddType === "marketwatcher"}
                                                    onChange={() => this.hideComponentMarketWatcher()}
                                                /> Market Watcher
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col sm={{ size: 8, order: 1, offset: 1 }}>
                                                <input
                                                    type="radio"
                                                    value="traderbot"
                                                    name="type"
                                                    size="sm"
                                                    checked={this.state.botAddType === "traderbot"}
                                                    onChange={this.hideComponentTraderBot}
                                                /> Trader Bot
                                            </Col>
                                        </Row>
                                    </Container>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>}

                    {this.state.showBotsStrategy && <BotsStrategy
                        changeTa4prof2={this.changeTa4prof2}
                        updateConfStrategyParam={this.confBotAddUpdateStrategyParam} />}



                    {this.state.showBotsSimulationsConf && <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="6">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Simulation Configuration</h3>
                                        </div>
                                        <div>
                                            <i className="fas fa-screwdriver text-success fa-2x"></i>
                                        </div>
                                        {/*<div className="col">
                        <i className="fas fa-btc text-success fa-2x"></i>
                    </div>*/}
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <BotsSimulationsConf
                                         updateConfSimulation={this.confBacktestAddUpdateSimulation}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>}

                    {this.state.showBotsStartButton && <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Start trading bot / paper tradingbot / market watcher</h3>
                                        </div>
                                        <div className="col text-right">
                                            <Button
                                                color="primary"

                                                onClick={() => this.goToBotsAdd()}
                                                size="sm"
                                            >
                                                Start
                                            </Button>
                                        </div>
                                    </Row>
                                </CardHeader>
                            </Card>
                        </Col>
                    </Row>}
                </Container>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { authenticated: state.auth.authenticated, user_credentials: state.auth.user_credentials };
}

export default compose(
    connect(mapStateToProps, actions)
)(withRouter(BotsAdd));
