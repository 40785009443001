//import Login from "components/Footers/AuthFooter";
import React, { Component } from 'react';
//import { reduxForm, Field } from 'redux-form';
//import { compose } from 'redux';
//import { connect } from 'react-redux';
//import * as actions from '../../actions'
import { Link, NavLink } from "react-router-dom";
import Header from '../Headers/Header';
import BotsDatasetsView from './BotsDataSetsView';
import DataSetImporterAdd from './DataSetImporterAdd';
import DataSetImporterView from './DataSetImporterView';
//import BotsStrategy from '../Bots/BotsAdd/BotsConf/BotsStrategy'
//import BotsDatasets from "./BotsDataSets/BotsDataSets";
//import BotsSimulationsConf from "components/Bots/BotsAdd/BotsConf/BotsSimulationConf";
//import BotsParameters from "components/Bots/BotsAdd/BotsConf/BotsParameters"
//import BacktestResultData from "./BacktestResultData/BacktestResultData";
//import BotsMarketGraphic from "../Bots/BotsGet/BotsMarketGraphic"
//import BotsTradesHistory from "components/Bots/BotsGet/BotsTradesHistory";
import DataImportTable from './DataImportTable';
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    //    FormGroup,
    //    Form,
    //    Input,
    //    InputGroupAddon,
    //    InputGroupText,
    //    InputGroup,
    Row,
    Col,
    Container,
    //    Progress,
    //    Spinner,
} from "reactstrap";

class LocalData extends Component {

    constructor() {
        super();

    }

    render() {

        return (

            <div>
                <Header />
                <Container className="mt--7" fluid >
                    <Row>
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Available Data Sets</h3>
                                        </div>
                                        <div className="col-md-6 text-right">
                                            <i className="fas fa-database text-success fa-2x"></i>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <BotsDatasetsView />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Currently running imports</h3>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <DataImportTable />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Import Data from Markets</h3>
                                        </div>
                                        <div className="col text-right">
                                            <Link
                                                className="btn btn-primary btn-sm"
                                                to="/admin/service/addLocalData"
                                                size="sm"

                                            >
                                                New
                                            </Link>
                                        </div>
                                    </Row>
                                </CardHeader>
                            </Card>
                        </Col>
                    </Row>


                </Container>

            </div>
        )
    }

}

export default LocalData;